import { useRef } from 'react';
import { _uuid } from '../utils/uuid';

type Props = {
	requestId: string;
	updateSearch: () => void;
};

export const useRequestId = (search: (page: number, searchId: string) => void): Props => {
	const requestId = useRef<string>(_uuid());

	const updateSearch = () => {
		requestId.current = _uuid();
		search(1, requestId.current);
	};

	return {
		requestId: requestId.current,
		updateSearch,
	};
};
