import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../api';
import { Option } from '../components/common/SearchSelect';
import PopupEnum from '../enum/popupEnum';
import RoleEnum from '../enum/roleEnum';
import { OperatorInfoData } from '../models';

export interface IMasterOperatorListStore {
	opApiWhiteList: { [fpId: string]: string[] };
	opList: OperatorInfoData[];
	options: Option[] | [];
	initOpList: (openPopup: (popup: PopupEnum) => void, role: RoleEnum, fpId: string) => void;
	getOpOptionByName: (name: string) => Option | null;
	clear: () => void;
}

const key = 'useMasterOperatorListStore';

export const useMasterOperatorListStore = create<IMasterOperatorListStore>()(
	devtools(
		persist(
			(set, get) => ({
				opList: [],
				options: [],
				opApiWhiteList: {},
				initOpList: async (openPopup, role: RoleEnum, fpId?) => {
					try {
						let operatorList: OperatorInfoData[] = [];
						if (role === RoleEnum.Master && fpId) {
							operatorList.push((await api.searchOperator({ operatorId: fpId })).operator as OperatorInfoData);
						} else {
							operatorList = (await api.searchMasterOperator()).operatorList as OperatorInfoData[];
						}
						set((state) => ({
							...state,
							opApiWhiteList: operatorList.reduce(
								(_result, { fpId, apiWhiteList }) => ({
									..._result,
									[fpId]: apiWhiteList,
								}),
								{}
							),
							opList: operatorList,
							options: operatorList.map((item) => ({
								id: item.fpId,
								label: item.name,
							})),
						}));
					} catch (error) {
						console.log(error);
						openPopup(PopupEnum.InternalServerError);
					}
				},
				getOpOptionByName: (opName) => {
					if (get().opList.length) {
						const opInfo = get().opList.find((op) => op.name.toLocaleLowerCase() === opName.toLocaleLowerCase());

						if (opInfo) return { id: opInfo.fpId, label: opInfo.name };
					}
					return null;
				},
				clear: () => set({ opList: [], options: [], opApiWhiteList: {} }),
			}),
			{
				name: key,
				getStorage: () => sessionStorage,
			}
		)
	)
);
