export const searchOperatorMock = {
	operator: {
		fpId: '1',
		name: 'Sylph',
		isMasterOperator: true,
		parentOperator: 0,
		resellerId: 0,
		apiWhiteList: ['string', 'string2', 'string3', 'string4'],
	},
};

export default searchOperatorMock;
