import { DialogContentText, Grid } from '@mui/material';
import { Fragment } from 'react';

export type PopupTableContentMessage = {
	title: string;
	content: string | undefined;
};

type Props = {
	messageLines: PopupTableContentMessage[];
};

export function PopupTableContent({ messageLines }: Props): JSX.Element {
	return (
		<Grid container rowSpacing={0.5}>
			{messageLines.map(({ title, content }: PopupTableContentMessage) => (
				<Fragment key={title}>
					<Grid item xs={5}>
						<DialogContentText>{title}: </DialogContentText>
					</Grid>
					<Grid item xs={7}>
						<DialogContentText>{content}</DialogContentText>
					</Grid>
				</Fragment>
			))}
		</Grid>
	);
}
