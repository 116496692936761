import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGameListStore } from '../store/gameListStore';
import { usePopupStore } from '../store/popupStore';
import SearchSelect, { Option } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	game: Option | null;
	setGame: (option: Option | null) => void;
	hasAll?: boolean;
};

export const GameSection = ({ game, setGame, hasAll }: Props) => {
	const { initGameList, transferToOptions } = useGameListStore();
	const { openPopup } = usePopupStore();
	const { t } = useTranslation();

	useEffect(() => {
		initGameList(openPopup);
	}, [initGameList, openPopup]);

	return (
		<div className="space-y-3">
			<SectionTitle content={t('Game')} />
			<SearchSelect
				label={t('search.Select_Game')}
				option={game}
				setOption={setGame}
				options={transferToOptions()}
				hasAll={hasAll}
			/>
		</div>
	);
};

export default GameSection;
