import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGameProviderListStore } from '../store/gameProviderListStore';
import { usePopupStore } from '../store/popupStore';
import SearchSelect, { Option, optionAll } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	gp: Option | null;
	setGp: (option: Option | null) => void;
	hasAll?: boolean;
};

export function GameProviderSection({ gp, setGp, hasAll }: Props) {
	const { initGpList, transferToOptions } = useGameProviderListStore();
	const { openPopup } = usePopupStore();
	const { t } = useTranslation();

	useEffect(() => {
		initGpList(openPopup);
	}, [initGpList, openPopup]);

	useEffect(() => {
		if(hasAll)
			setGp(optionAll);
	}, [hasAll, setGp]);

	return (
		<div className="space-y-3">
			<SectionTitle content={t('Game_Provider')} />
			<SearchSelect
				label={t('search.Select_Game_Provider')}
				option={gp}
				setOption={setGp}
				options={transferToOptions()}
				hasAll={hasAll}
			/>
		</div>
	);
}

export default GameProviderSection;
