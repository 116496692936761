import { GetBetByRefNoResponse } from './../models/GetBetByRefNoResponse';
import axios from 'axios';
import PopupEnum from '../enum/popupEnum';
import {
	CreateAccountRequest,
	CreateSubLineRequisitionRequest,
	CreateSubLineRequisitionResponse,
	DownloadGameWinLoseRequest,
	DownloadGpWinLoseRequest,
	DownloadOperatorWinLoseRequest,
	DownloadPlayerWinLoseRequest,
	DownloadTransactionReportRequest,
	DownloadTransferWalletBetListRequest,
	DownloadTransferWalletTransactionHistoryRequest,
	GetAccountSettingResponse,
	GetBetDetailByRefNoRequest,
	GetBetDetailByRefNoResponse,
	GetCurrencyListResponse,
	GetGameListResponse,
	GetGameProviderListResponse,
	GetOperatorListResponse,
	GetSubLineRequisitionsRequest,
	GetSubLineRequisitionsResponse,
	LoginRequest,
	LoginResponse,
	QueryAccountRequest,
	QueryAccountResponse,
	ResetPasswordRequest,
	ResetPasswordResponse,
	ResponseBase,
	SearchMasterOperatorResponse,
	SearchOperatorRequest,
	SearchOperatorResponse,
	SearchTransactionReportRequest,
	SearchTransactionReportResponse,
	SearchTransferWalletBetListRequest,
	SearchTransferWalletBetListResponse,
	SearchTransferWalletTransactionHistoryRequest,
	SearchTransferWalletTransactionHistoryResponse,
	SearchWinLoseReportByGameRequest,
	SearchWinLoseReportByGameResponse,
	SearchWinLoseReportByPlayerRequest,
	SearchWinLoseReportByPlayerResponse,
	SearchWinLoseReportRequest,
	SearchWinLoseReportResponse,
	SearchWinLoseReportByGameProviderRequest,
	SearchWinLoseReportByGameProviderResponse,
	UpdateAccountProfileRequest,
	UpdatePasswordRequest,
	UpdatePasswordResponse,
} from '../models';
import { GetBetByRefNoRequest } from '../models/GetBetByRefNoRequest';
import { useAuthStore } from '../store/authStore';
import * as mock from './mocks';

const axiosPost = axios.create({
	baseURL: '/dobby/api/',
}).post;

const axiosDownload = axios.create({
	baseURL: '/dobby/api/',
	responseType: 'blob',
}).post;

const createAuthConfig = (token: string | boolean) =>
	token ? { headers: { Authorization: `Bearer ${token}`, 'content-type': 'application/json' } } : {};

const isDev = process.env.NODE_ENV === 'development';

const mockPost = (path: string, request?: any | null) => {
	const res = mock as { [key: string]: any };
	const pathKey = path.split('/').at(-1);
	if (!pathKey) throw PopupEnum.FetchError;
	const key = pathKey[0].toLocaleLowerCase() + pathKey?.slice(1) + 'Mock';
	console.log(path, request);

	return {
		status: 200,
		statusText: 'OK',
		data: { ...res[key], returnCode: '0', message: '' },
		request,
		headers: {},
	};
};

const post = (path: string, req?: any | null, isAuth: boolean = true) =>
	isDev
		? mockPost(path, req)
		: axiosPost(path, req, createAuthConfig((isAuth && useAuthStore.getState().authState?.token) || false));

const download = (path: string, req: any | null) =>
	isDev
		? {
			data: 'blob:http://dobby-stg.funkytest.com/1158d01b-43d0-4970-b3ad-12e35445cf6d',
			headers: {
				'content-disposition':
					"attachment; filename=FunkyGamesInvoice_2022-07_1_202207193.xlsx; filename*=UTF-8''FunkyGamesInvoice_2022-07_1_202207193.xlsx",
			},
		}
		: axiosDownload(path, req, createAuthConfig(useAuthStore.getState().authState?.token || false));

interface IApi {
	login: (req: LoginRequest) => Promise<LoginResponse>;
	getOperatorList: () => Promise<GetOperatorListResponse>;
	getGameProviderList: () => Promise<GetGameProviderListResponse>;
	getGameList: () => Promise<GetGameListResponse>;
	searchWinLoseReport: (req: SearchWinLoseReportRequest) => Promise<SearchWinLoseReportResponse>;
	searchWinLoseReportByGameProvider: (req: SearchWinLoseReportByGameProviderRequest) => Promise<SearchWinLoseReportByGameProviderResponse>;
	searchWinLoseReportByPlayer: (
		req: SearchWinLoseReportByPlayerRequest
	) => Promise<SearchWinLoseReportByPlayerResponse>;
	searchWinLoseReportByGame: (req: SearchWinLoseReportByGameRequest) => Promise<SearchWinLoseReportByGameResponse>;
	downloadOperatorWinLose: (req: DownloadOperatorWinLoseRequest) => Promise<any>;
	downloadGameWinLose: (req: DownloadGameWinLoseRequest) => Promise<any>;
	downloadPlayerWinLose: (req: DownloadPlayerWinLoseRequest) => Promise<any>;
	downloadGpWinLose: (req: DownloadGpWinLoseRequest) => Promise<any>;
	searchTransactionReport: (req: SearchTransactionReportRequest) => Promise<SearchTransactionReportResponse>;
	downloadTransactionReport: (req: DownloadTransactionReportRequest) => Promise<any>;
	searchTransferWalletBetList: (
		req: SearchTransferWalletBetListRequest
	) => Promise<SearchTransferWalletBetListResponse>;
	getBetByRefNo: (req: GetBetByRefNoRequest) => Promise<GetBetByRefNoResponse>;
	downloadTransferWalletBetList: (req: DownloadTransferWalletBetListRequest) => Promise<any>;
	searchTransferWalletTransactionHistory: (
		req: SearchTransferWalletTransactionHistoryRequest
	) => Promise<SearchTransferWalletTransactionHistoryResponse>;
	downloadTransferWalletTransactionHistory: (req: DownloadTransferWalletTransactionHistoryRequest) => Promise<any>;
	getBetDetailByRefNo: (req: GetBetDetailByRefNoRequest) => Promise<GetBetDetailByRefNoResponse>;
	updatePassword: (req: UpdatePasswordRequest) => Promise<UpdatePasswordResponse>;
	queryAccount: (req: QueryAccountRequest) => Promise<QueryAccountResponse>;
	createAccount: (req: CreateAccountRequest) => Promise<ResponseBase>;
	getAccountSetting: () => Promise<GetAccountSettingResponse>;
	updateAccountProfile: (req: UpdateAccountProfileRequest) => Promise<ResponseBase>;
	resetPassword: (req: ResetPasswordRequest) => Promise<ResetPasswordResponse>;
	searchOperator: (req: SearchOperatorRequest) => Promise<SearchOperatorResponse>;
	searchMasterOperator: () => Promise<SearchMasterOperatorResponse>;
	getSubLineRequisitions: (req: GetSubLineRequisitionsRequest) => Promise<GetSubLineRequisitionsResponse>;
	createSubLineRequisition: (req: CreateSubLineRequisitionRequest) => Promise<CreateSubLineRequisitionResponse>;
	getCurrencyList: () => Promise<GetCurrencyListResponse>;
}

export const api: IApi = {
	login: async (req) => {
		const res = await post('login', req, false);
		return res.data;
	},
	getOperatorList: async () => {
		const res = await post('operator/getOperatorList');
		return res.data;
	},
	getGameProviderList: async () => {
		const res = await post('gameProvider/getGameProviderList');
		return res.data;
	},
	getGameList: async () => {
		const res = await post('game/getGameList');
		return res.data;
	},
	searchWinLoseReport: async (req) => {
		const res = await post('report/searchWinLoseReport', req);
		return res.data;
	},
	searchWinLoseReportByGameProvider: async (req) => {
		const res = await post('report/SearchWinLoseReportByGameProvider', req);
		return res.data;
	},
	searchWinLoseReportByPlayer: async (req) => {
		const res = await post('report/searchWinLoseReportByPlayer', req);
		return res.data;
	},
	searchWinLoseReportByGame: async (req) => {
		const res = await post('report/searchWinLoseReportByGame', req);
		return res.data;
	},
	downloadOperatorWinLose: async (req) => {
		const res = await download('/report/downloadOperatorWinLose', req);
		return [res.data, res.headers['content-disposition']];
	},
	downloadGameWinLose: async (req) => {
		const res = await download('/report/downloadGameWinLose', req);
		return [res.data, res.headers['content-disposition']];
	},
	downloadGpWinLose: async (req) => {
		const res = await download('/report/DownloadOperatorWinLoseByGameProvider', req);
		return [res.data, res.headers['content-disposition']];
	},
	downloadPlayerWinLose: async (req) => {
		const res = await download('/report/downloadPlayerWinLose', req);
		return [res.data, res.headers['content-disposition']];
	},
	searchTransactionReport: async (req) => {
		const res = await post('report/searchTransactionReport', req);
		return res.data;
	},
	getBetByRefNo: async (req) => {
		const res = await post('report/getBetByRefNo', req);
		return res.data;
	},
	downloadTransactionReport: async (req) => {
		const res = await download('/report/downloadTransactionReport', req);
		return [res.data, res.headers['content-disposition']];
	},
	searchTransferWalletTransactionHistory: async (req) => {
		const res = await post('/transferWalletReport/searchTransferWalletTransactionHistory', req);
		return res.data;
	},
	searchTransferWalletBetList: async (req) => {
		const res = await post('/transferWalletReport/searchTransferWalletBetList', req);
		return res.data;
	},
	downloadTransferWalletTransactionHistory: async (req) => {
		const res = await download('/transferWalletReport/downloadTransferWalletTransactionHistory', req);
		return [res.data, res.headers['content-disposition']];
	},
	downloadTransferWalletBetList: async (req) => {
		const res = await download('/transferWalletReport/downloadTransferWalletBetList', req);
		return [res.data, res.headers['content-disposition']];
	},
	getBetDetailByRefNo: async (req) => {
		const res = await post('/bet/getBetDetailByRefNo', req);
		return res.data;
	},
	updatePassword: async (req) => {
		const res = await post('/account/updatePassword', req);
		return res.data;
	},
	queryAccount: async (req) => {
		const res = await post('/account/queryAccount', req);
		return res.data;
	},
	createAccount: async (req) => {
		const res = await post('/account/createAccount', req);
		return res.data;
	},
	getAccountSetting: async () => {
		const res = await post('/account/getAccountSetting');
		return res.data;
	},
	updateAccountProfile: async (req) => {
		const res = await post('/account/updateAccountProfile', req);
		return res.data;
	},
	resetPassword: async (req) => {
		const res = await post('/account/resetPassword', req);
		return res.data;
	},
	searchOperator: async (req) => {
		const res = await post('/Operator/SearchOperator', req);
		return res.data;
	},
	searchMasterOperator: async () => {
		const res = await post('/Operator/SearchMasterOperator');
		return res.data;
	},
	getSubLineRequisitions: async (req) => {
		const res = await post('/SubLine/GetSubLineRequisitions', req);
		return res.data;
	},
	createSubLineRequisition: async (req) => {
		const res = await post('/SubLine/CreateSubLineRequisition', req);
		return res.data;
	},
	getCurrencyList: async () => {
		const res = await post('/Setting/GetCurrencyList');
		return res.data;
	},
};

export default api;
