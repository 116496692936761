import { SearchTransferWalletTransactionHistoryResponse } from '../../models';

export const searchTransferWalletTransactionHistoryMock: SearchTransferWalletTransactionHistoryResponse = {
	returnCode: '0',
	message: '',
	currentPage: 1,
	pageCount: 1,
	pageSize: 100,
	rowCount: 2,
	fpId: '0',
	agentName: 'xxx',
	timeZone: '4',
	data: [
		{
			txId: 'Funky_sylphT_9801528',
			txSerialId: 1234,
			currency: '123.00',
			amount: '123.00',
			txTime: 'ttt',
			txType: 'aaa',
			status: 'settle',
		},
		{
			txId: '1222222222222222222222222222888888',
			txSerialId: 2345,
			currency: '123.00',
			amount: '123.00',
			txTime: 'ttt',
			txType: 'aaa',
			status: 'settle',
		},
	],
};

export default searchTransferWalletTransactionHistoryMock;
