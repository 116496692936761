import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PopupEnum from '../enum/popupEnum';
import { Paper, FormControl, TextField, IconButton, Stack } from '@mui/material';
import { Visibility, VisibilityOff, Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import PageTitle from '../components/common/PageTitle';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../store/authStore';
import { usePopupStore } from '../store/popupStore';
import { useError } from '../hook/useError';
import api from '../api';
import { PASSWORD_RULE } from '../utils/regexp';
import { I18nStoreType } from '../i18n/react-i18next';

const validationSchema = yup
	.object({
		oldPassword: yup.string().required('inputErrorMsg.Required'),
		newPassword: yup
			.string()
			.min(8, 'inputErrorMsg.Size_8_10')
			.max(10, 'inputErrorMsg.Size_8_10')
			.matches(PASSWORD_RULE, 'inputErrorMsg.Valid')
			.required('inputErrorMsg.Required'),
		confirmNewPassword: yup
			.string()
			.oneOf([yup.ref('newPassword'), null], 'inputErrorMsg.NotMatch')
			.required('inputErrorMsg.Required'),
	})
	.required();

type FormData = {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
};

type Props = {};

export const ChangePasswordPage = (props: Props) => {
	const { t } = useTranslation();
	const { authState } = useAuthStore();
	const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [validClassName, setValidClassName] = useState({
		size: false,
		combination: false,
		letter: false,
	});
	const { openPopup } = usePopupStore();
	const { handleError } = useError();
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors, isSubmitSuccessful },
		watch,
	} = useForm<FormData>({
		resolver: yupResolver(validationSchema),
	});

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

	const onSubmitHandler = async (data: FormData) => {
		try {
			openPopup(PopupEnum.Loading);
			const { confirmNewPassword, ...formData } = data;
			await api.updatePassword({
				currentPassword: formData.oldPassword,
				newPassword: formData.newPassword,
			});

			openPopup(PopupEnum.UpdateSuccess);
		} catch (error: any | PopupEnum) {
			handleError(error);
		}
	};

	const newPassword = watch('newPassword');

	useEffect(() => {
		const size = newPassword?.length >= 8 && newPassword?.length <= 10;
		const combination = /[a-zA-Z]/.test(newPassword) && /\d/.test(newPassword);
		const letter = /[a-z]/.test(newPassword) && /[A-Z]/.test(newPassword);

		setValidClassName({ size, combination, letter });
	}, [newPassword]);

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful, reset]);

	return (
		<div className="space-y-4  mx-auto w-[500px]">
			<PageTitle content={t('setting.ChangePassword.Title')} />
			<Paper
				className="p-8 bg-gray-50"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				<FormControl fullWidth className="mt-8 mb-4">
					<TextField
						label={t('setting.ChangePassword.Username')}
						variant="outlined"
						disabled
						value={authState?.account}
					/>
				</FormControl>
				<FormControl fullWidth className="my-4">
					<TextField
						label={t('setting.ChangePassword.OldPassword')}
						variant="outlined"
						type={showOldPassword ? 'text' : 'password'}
						autoFocus
						error={!!errors['oldPassword']}
						helperText={
							errors['oldPassword'] ? (t(errors['oldPassword'].message as keyof I18nStoreType) as string) : ''
						}
						{...register('oldPassword')}
					/>
					<IconButton
						className="absolute right-3"
						aria-label="toggle password visibility"
						onClick={() => setShowOldPassword((value) => !value)}
						onMouseDown={handleMouseDownPassword}
						edge="end"
					>
						{showOldPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</FormControl>
				<FormControl fullWidth className="my-4">
					<TextField
						label={t('setting.ChangePassword.NewPassword')}
						variant="outlined"
						type={showNewPassword ? 'text' : 'password'}
						error={!!errors['newPassword']}
						{...register('newPassword')}
					/>
					<IconButton
						className="absolute right-3"
						aria-label="toggle password visibility"
						onClick={() => setShowNewPassword((value) => !value)}
						onMouseDown={handleMouseDownPassword}
						edge="end"
					>
						{showNewPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</FormControl>
				<Stack className="mb-4">
					<div className={`flex space-x-2 mb-2 ${validClassName.size ? 'italic' : 'text-red-600'}`}>
						{!!validClassName.size && <Check />}
						<span>{t('inputErrorMsg.Size_8_10')}</span>
					</div>
					<div className={`flex space-x-2 mb-2 ${validClassName.combination ? 'italic' : 'text-red-600'}`}>
						{!!validClassName.combination && <Check />}
						<span>{t('inputErrorMsg.Valid_Combination')}</span>
					</div>
					<div className={`flex space-x-2 ${validClassName.letter ? 'italic' : 'text-red-600'}`}>
						{!!validClassName.letter && <Check />}
						<span>{t('inputErrorMsg.Valid_Letter')}</span>
					</div>
				</Stack>
				<FormControl fullWidth className="my-4">
					<TextField
						label={t('setting.ChangePassword.ConfirmNewPassword')}
						variant="outlined"
						type={showConfirmPassword ? 'text' : 'password'}
						error={!!errors['confirmNewPassword']}
						helperText={
							errors['confirmNewPassword']
								? (t(errors['confirmNewPassword'].message as keyof I18nStoreType) as string)
								: ''
						}
						{...register('confirmNewPassword')}
					/>
					<IconButton
						className="absolute right-3"
						aria-label="toggle password visibility"
						onClick={() => setShowConfirmPassword((value) => !value)}
						onMouseDown={handleMouseDownPassword}
						edge="end"
					>
						{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</FormControl>
				<FormControl fullWidth className="my-4">
					<LoadingButton variant="gradient" className="mx-auto mt-6" type="submit">
						{t('setting.ChangePassword.SubmitButton')}
					</LoadingButton>
				</FormControl>
			</Paper>
		</div>
	);
};

export default ChangePasswordPage;
