export enum SystemRoleEnum {
	Admin = 'Admin',
	Reseller = 'Reseller',
	SmlMaster = 'SmlMaster',
	SmlManager = 'SmlManager',
	TrMaster = 'TrMaster',
	TrManager = 'TrManager',
}

export default SystemRoleEnum;
