import AddIcon from '@mui/icons-material/Add';
import { Chip, IconButton, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HintMsg from './HintMsg';
import SectionTitle from './SectionTitle';

export type Props = {
	title: string;
	list: string[];
	setList: Dispatch<SetStateAction<string[]>>;
	validate: (item: string) => boolean;
	errorText: string;
	emptyText: string;
	label?: string;
};

export function ArrayTextInput({ title, list, setList, errorText, emptyText, validate, label }: Props): JSX.Element {
	const [value, setValue] = useState<string>('');
	const { t } = useTranslation();
	const addList = (item: string) => {
		if (!validate(item)) return;
		setList((value) => [...value, item]);
		setValue('');
	};

	const dropList = (item: string) => {
		setList((currentList) => currentList.filter((value) => value !== item));
	};

	return (
		<div className="space-y-3 w-full col-span-12">
			<SectionTitle content={title} />
			<div className="flex flex-wrap gap-1">
				{list.length > 0 ? (
					list.map((item) => <Chip key={item} label={item} variant="filled" onDelete={() => dropList(item)} />)
				) : (
					<HintMsg content={emptyText} />
				)}
			</div>
			<div className="space-x-3 w-full flex items-start">
				<TextField
					className="w-full flex-1"
					variant="outlined"
					autoComplete="off"
					value={value}
					onChange={({ target }) => setValue(target.value)}
					onKeyDown={(e) => {
						if (e.key !== 'Enter') return;
						e.preventDefault();
						addList(value);
					}}
					error={!!errorText}
					helperText={errorText}
					label={label || t('inputHintMsg.White_List_Enter')}
				/>
				<IconButton color="primary" disabled={!value} onClick={() => addList(value)}>
					<AddIcon />
				</IconButton>
			</div>
		</div>
	);
}
