import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HintMsg from './common/HintMsg';
import { SearchButton } from './common/SearchButton';

type Props = {
	handleSearch: () => void;
	showCurrencyHint?: boolean;
	children: React.ReactNode;
};

export function SearchWrapper({ handleSearch, showCurrencyHint, children }: Props) {
	const { t } = useTranslation();
	return (
		<div className="space-y-2">
			<Paper elevation={3} className="p-6 bg-gray-50 flex flex-col gap-12">
				<div className="flex flex-wrap gap-6">{children}</div>
				<SearchButton handleSearch={handleSearch} />
			</Paper>
			{showCurrencyHint && <HintMsg content={t('search.Report_Currency_Memo')} />}
		</div>
	);
}
