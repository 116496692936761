import { useState, useEffect } from 'react';
import SearchSelect, { Option } from './SearchSelect';
import SectionTitle from './SectionTitle';

type Props = {
	widthStyle?: string;
	option: Option | null;
	setOption: (option: Option<any> | null) => void;
	optionEnum: Object;
	title: string;
	label?: string;
	required?: boolean;
	errorText?: string;
	disabled?: boolean;
};

export const EnumSelectSection = ({
	widthStyle,
	option,
	setOption,
	optionEnum,
	title,
	label,
	required,
	errorText,
	disabled = false,
}: Props) => {
	const [options, setOptions] = useState<Option[]>([]);

	useEffect(() => {
		const optionList = Object.keys(optionEnum).map((status) => {
			return { id: status, label: status };
		});
		setOptions(optionList);
	}, [optionEnum]);

	return (
		<div className={`space-y-3 ${widthStyle || 'w-[200px]'}`}>
			<SectionTitle content={title} required={required} />
			<SearchSelect
				widthStyle="w-full"
				label={label}
				option={option}
				setOption={setOption}
				options={options}
				errorText={errorText}
				disabled={disabled}
			/>
		</div>
	);
};

export default EnumSelectSection;
