import { ChipList, ChipListProps } from './ChipList';

type Props = {
	info: ChipListProps;
};

export function TableHeader({ info }: Props) {
	return (
		<div className="flex justify-between items-center py-2">
			<div className="flex flex-wrap gap-3">
				<ChipList {...info} />
			</div>
		</div>
	);
}
