import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../api';
import { AccountTypeEnum } from '../enum/accountTypeEnum';
import { RoleEnum } from '../enum/roleEnum';

export interface IAccountSettingStore {
	accountType: AccountTypeEnum | null;
	role: RoleEnum | null;
	getSetting: () => Promise<any>;
	clear: () => void;
}

export const useAccountSettingStore = create<IAccountSettingStore>()(
	devtools(
		persist(
			(set, get) => ({
				accountType: null,
				role: null,
				getSetting: async () => {
					try {
						const res = await api.getAccountSetting();
						set({ accountType: res.accountType as AccountTypeEnum, role: res.accountRole as RoleEnum });
					} catch (e) {
						console.log(e);
					}
				},
				clear: () => ({ accountType: null, role: null }),
			}),
			{
				name: 'accountSettingStore',
				getStorage: () => sessionStorage,
			}
		)
	)
);
