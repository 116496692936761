import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FunkyLogo } from '../assets/logo_w.svg';
import { Stack, Button, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useAuthStore } from '../store/authStore';
import { useLoginStore } from '../store/loginStore';

const Header: React.FC = () => {
	const go = useNavigate();
	const { t } = useTranslation();
	const { authState } = useAuthStore();
	const { logout } = useLoginStore();

	return (
		<div className="h-[120px] w-full bg-primary px-4 py-4 md:px-16">
			<div className="h-full w-full flex items-center justify-end sm:justify-between">
				<FunkyLogo className="hidden sm:block cursor-pointer" onClick={() => go('/')} />
				<div className="text-gray-50 space-y-2">
					<span>
						{t('login_time')}: {authState?.loginDate}
					</span>
					<div className="flex justify-between items-center space-x-2">
						<Stack direction="row" className="items-center">
							<PersonIcon fontSize="medium" />
							<Typography variant="body1" className="ml-2">
								{authState?.account}
							</Typography>
						</Stack>
						<Button variant="whiteOutlined" size="small" onClick={() => logout()}>
							{t('logout')}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
