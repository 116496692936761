import { LoginResponse } from '../../models';

export const loginMock: LoginResponse = {
	returnCode: '0',
	message: '',
	account: 'TESTMASTER01',
	token:'123',
	role: 'Admin',
	// role: 'Reseller',
	// role: 'SmlMaster',
	// role: 'Manager',
	timeZone: '0',
	isDefaultPassword: true,
	fpId: '1',
};

export default loginMock;
