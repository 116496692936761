import { Typography } from '@mui/material';

type Props = {
	content: string;
	required?: boolean;
};

const SectionTitle = ({ content, required }: Props) => (
	<Typography variant="subtitle1">
		{content}
		{required && <span className="text-[#ae0000]"> *</span>}
	</Typography>
);

export default SectionTitle;
