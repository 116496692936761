import PopupEnum from './popupEnum';

export const ReturnCodeEnum: { [key: string]: PopupEnum } = {
	SYS0004: PopupEnum.PasswordNotCorrect,
	SYS0005: PopupEnum.SamePassword,
	SYS0007: PopupEnum.DuplicateId,
	'101': PopupEnum.OperatorNameExisted,
	'102': PopupEnum.OperatorNameInvalid,
	'103': PopupEnum.UrlInvalid,
	'104': PopupEnum.WhiteListInvalid,
};

export default ReturnCodeEnum;
