import { useTranslation } from 'react-i18next';
import { formatTimeZone } from '../utils/date';
import { CustomChip } from './common/CustomChip';

export type ChipListProps = {
	operatorName?: string;
	gameProviderName?: string;
	gameName?: string;
	playerId?: string;
	timeZone?: string;
	totalBetCount?: string;
	totalActivePlayer?: string;
};

export function ChipList({
	operatorName,
	gameProviderName,
	gameName,
	playerId,
	timeZone,
	totalBetCount,
	totalActivePlayer,
}: ChipListProps) {
	const { t } = useTranslation();

	return (
		<>
			<CustomChip color={'primary'} value={operatorName} />
			<CustomChip color={'secondary'} value={gameProviderName} />
			<CustomChip color={'secondary'} value={gameName} />
			<CustomChip color={'secondary'} value={playerId} />
			<CustomChip label="GMT" value={timeZone && formatTimeZone(timeZone)} />
			<CustomChip label={t('reportTable.TotalBetCount')} value={totalBetCount} />
			<CustomChip label={t('reportTable.TotalActivePlayer')} value={totalActivePlayer} />
		</>
	);
}
