import { FirstPage, LastPage } from '@mui/icons-material';
import { Box, Button, Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ManagementIcon } from '../assets/management.svg';
import { ReactComponent as ReportIcon } from '../assets/report.svg';
import { ReactComponent as SettingIcon } from '../assets/setting.svg';
import SystemRoleEnum from '../enum/systemRoleEnum';
import { gpWinLoseAllowList } from '../utils/allowList';
import CollapseButton from './common/CollapseButton';
import LanguageSelect from './common/LanguageSelect';

const SideBar = () => {
	const { t } = useTranslation();
	const [isHidden, setIsHidden] = useState(false);
	const toggleSidebar = () => {
		setIsHidden(!isHidden);
	};

	if (isHidden) {
		return (
			<Paper className="bg-[#e0e6f0] w-auto">
				<Button variant="text" className="h-[100%] p-4 hover:pl-20 transition-all" onClick={toggleSidebar}>
					<LastPage className="w-7 h-7" />
				</Button>
			</Paper>
		);
	}

	return (
		<Paper className="min-w-[300px] max-w-[300px] bg-[#e0e6f0] px-3 py-3 h-full flex-1 flex flex-col gap-4 justify-between">
			<Stack className="space-y-6 overflow-auto ">
				<LanguageSelect />
				<Box className="p-1">
					<CollapseButton
						icon={<ReportIcon className="scale-150" />}
						text={t('sidebar.report.title')}
						subList={[
							{ label: t('sidebar.report.winlose'), path: '/winlose', newTab: true },
							{
								label: t('sidebar.report.winloseByGameProvider'),
								path: '/winloseByGameProvider',
								newTab: true,
								allowedRoles: [SystemRoleEnum.Admin,SystemRoleEnum.SmlMaster],
								allowedFpIds: [...gpWinLoseAllowList],
							},
							{
								label: t('sidebar.report.winloseByCurrency'),
								path: '/winLoseGame',
								newTab: true,
							},
							{ label: t('sidebar.report.winloseByPlayer'), path: '/winLosePlayer', newTab: true },

							{
								label: t('sidebar.report.transaction'),
								path: '/transactionReport',
								newTab: true,
							},
							{
								label: t('sidebar.report.singleBetQuery'),
								path: '/singleBetQuery',
								newTab: true,
							},
							{
								label: t('sidebar.report.transferWalletBetList'),
								path: '/trBettingList',
								newTab: true,
								allowedRoles: [
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.TrManager,
									SystemRoleEnum.TrMaster,
								],
							},
							{
								label: t('sidebar.report.transferWalletTransactionHistory'),
								path: '/trTransactionHistory',
								newTab: true,
								allowedRoles: [
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.TrManager,
									SystemRoleEnum.TrMaster,
								],
							},
						]}
						defaultOpen
					/>
				</Box>
				<Box className="p-1">
					<CollapseButton
						icon={<SettingIcon className="scale-150" />}
						text={t('sidebar.setting.title')}
						subList={[{ label: t('sidebar.setting.changePassword'), path: '/changePassword' }]}
					/>
				</Box>
				<Box className="p-1">
					<CollapseButton
						icon={<ManagementIcon className="scale-150" />}
						text={t('sidebar.management.title')}
						allowedRoles={[
							SystemRoleEnum.Admin,
							SystemRoleEnum.Reseller,
							SystemRoleEnum.SmlMaster,
							SystemRoleEnum.TrMaster,
						]}
						subList={[
							{
								label: t('sidebar.management.member'),
								path: '/member',
								allowedRoles: [
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.SmlMaster,
									SystemRoleEnum.TrMaster,
								],
							},
							{
								label: t('sidebar.setting.addNewSubLine'),
								path: '/addNewSubLine',
								allowedRoles: [
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.SmlMaster,
									SystemRoleEnum.TrMaster,
								],
							},
							{
								label: t('sidebar.setting.subLineRequisition'),
								path: '/subLineRequisition',
								allowedRoles: [
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.SmlMaster,
									SystemRoleEnum.TrMaster,
								],
							},
						]}
					/>
				</Box>
			</Stack>
			<div className="w-full flex justify-end">
				<Button
					variant="outlined"
					color="primary"
					className="w-[35%] transition-all hover:w-[100%] flex gap-2"
					onClick={toggleSidebar}
				>
					<FirstPage className="w-5 h-5" />
					{t('sidebar.hide')}
				</Button>
			</div>
		</Paper>
	);
};

export default SideBar;
