import Button from '@mui/material/Button';

type Props = {
	value: string;
	click: (refNo: string) => void;
};

export const LinkCol = ({ value, click }: Props) => {
	return (
		<Button className="text-left" variant="text" onClick={() => click(value)}>
			{value}
		</Button>
	);
};
export default LinkCol;
