import { Backdrop, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PopupEnum from '../../enum/popupEnum';
import { usePopupStore } from '../../store/popupStore';
import { I18nStoreType } from '../../i18n/react-i18next';

type Props = {};


export function Popup(props: Props) {
	const { t } = useTranslation();
	const { popup, message, closePopup } = usePopupStore();
	const isLoading = popup === PopupEnum.Loading;
	const close = () => (isLoading ? null : closePopup());

	const getMessages = (): string => {
		if (!popup) return '';
		if (popup === PopupEnum.CustomMessage) return message;
		return t(`popup.${popup.toString()}` as keyof I18nStoreType).toString();

	}


	return (
		<Backdrop open={!!popup} sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}>
			<div className="w-[50%] min-w-[300px] h-80 bg-white opacity-95 rounded-lg flex flex-col gap-8 justify-center items-center px-4">
				<span className="text-xl font-bold text-primary-dark">{getMessages()}</span>
				<Button variant="gradient" onClick={close} autoFocus>
					{isLoading ? <CircularProgress color="inherit" size="1.5rem" /> : t('popup.Button_Ok')}
				</Button>
			</div>
		</Backdrop >
	);
}

export default Popup;
