import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoleEnum from '../enum/roleEnum';
import { useAccountSettingStore } from '../store/accountSettingStore';
import { useOperatorListStore } from '../store/operatorListStore';
import { usePopupStore } from '../store/popupStore';
import SearchSelect, { Option, optionAll } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	widthStyle?: string;
	op: Option | null;
	setOp: (option: Option | null) => void;
	hasAll?: boolean;
	required?: boolean;
	errorText?: string;
	filterRole?: RoleEnum;
};

export function OperatorSection({ widthStyle, op, setOp, hasAll, required, errorText, filterRole }: Props) {
	const { initOpList, optionDictionary } = useOperatorListStore();
	const { openPopup } = usePopupStore();
	const { t } = useTranslation();
	const { role } = useAccountSettingStore();
	const [options, setOptions] = useState<Option<string>[] | null>(null);

	useEffect(() => {
		initOpList(openPopup);
	}, [initOpList, openPopup]);

	useEffect(() => {
		if (!optionDictionary) return;
		setOptions(optionDictionary[filterRole || RoleEnum.Admin]);
		setOp(null);
	}, [optionDictionary, options, filterRole, setOp]);

	useEffect(() => {
		if (!role || !options) return;
		if (role === RoleEnum.Manager) {
			setOp(options[0]);
			return;
		}
		if (hasAll) {
			setOp(optionAll);
		}
	}, [options, role, hasAll, setOp]);

	return (
		<div className={`space-y-3 ${widthStyle || 'w-[200px]'}`}>
			<SectionTitle content={t('Operator')} required={required} />
			<SearchSelect
				widthStyle="w-full"
				label={t('search.Select_Operator')}
				option={op}
				setOption={setOp}
				options={options || []}
				hasAll={hasAll}
				errorText={errorText}
			/>
		</div>
	);
}

export default OperatorSection;
