import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
	content: string;
};

const AlertMsg = ({ content }: Props) => (
	<Typography variant="subtitle2" color="error" className="flex items-center gap-1">
		<ErrorOutlineIcon color="error" />
		<span>{content}</span>
	</Typography>
);

export default AlertMsg;
