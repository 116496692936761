import { useRef, useState } from 'react';

type Props = {
	currentPage: number;
	pageSize: number;
	pageCount: number;
	handlePage: (page: number, count?: number) => void;
};

export const usePage = (size: number = 100): Props => {
	const [currentPage, setCurrentPage] = useState<number>(0);
	const pageCount = useRef<number>(0);

	const handlePage = (page: number, count?: number) => {
		setCurrentPage(page);
		if (count) pageCount.current = count;
	};

	return {
		currentPage,
		handlePage,
		pageSize: size,
		pageCount: pageCount.current,
	};
};
