import { PopupEnum } from '../enum/popupEnum';
import create from 'zustand';

export interface IPopupStore {
	popup: null | PopupEnum;
	message: string;
	openPopup: (popup: PopupEnum) => void;
	closePopup: () => void;
	setPopup: (popup: PopupEnum, message: string) => void;
}

export const usePopupStore = create<IPopupStore>((set) => ({
	popup: null,
	message: '',
	openPopup: (popup) => set((state) => ({ ...state, popup })),
	closePopup: () => set((state) => ({ ...state, popup: null })),
	setPopup: (popup, message) => set((state) => ({ ...state, popup, message })),
}));
