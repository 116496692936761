import { Checkbox } from '@mui/material';

type Props = {
	list: CheckItem[];
};

export type CheckItem = {
	checked: boolean;
	toggle: () => void;
	text: string;
};

const CheckboxList = ({ list }: Props) => {
	return (
		<div className="flex flex-wrap gap-3 items-end">
			{list.map(({ checked, toggle, text }) => (
				<label key={text} className="font-semibold flex items-center cursor-pointer">
					<Checkbox checked={checked} onChange={toggle} />
					{text}
				</label>
			))}
		</div>
	);
};

export default CheckboxList;
