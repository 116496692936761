import { useLocation, Navigate, Outlet } from 'react-router-dom';
import SystemRoleEnum from '../enum/systemRoleEnum';
import { useAuthStore } from '../store/authStore';

type Props = {
	allowedRoles?: SystemRoleEnum[];
	allowedFpIds?: string[];
};

const RequireAuth: React.FC<Props> = ({ allowedRoles = [], allowedFpIds = [] }) => {
	const location = useLocation();
	const { authState } = useAuthStore();

	if (!authState) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (!allowedRoles.length || !allowedFpIds.length) {
		return <Outlet />;
	}

	return allowedRoles.includes(authState.role) || allowedFpIds.includes(authState.fpId) ? (
		<Outlet />
	) : (
		<Navigate to="/" state={{ from: location }} replace />
	);
};

export default RequireAuth;
