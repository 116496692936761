import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {};

export const NewsPage = (props: Props) => {
	const navigate = useNavigate();

	useEffect(() => navigate('/winlose'), [navigate]);

	return <h2> News ~~~ </h2>;
};

export default NewsPage;
