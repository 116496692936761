import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RoleEnum from '../enum/roleEnum';
import { useAccountSettingStore } from '../store/accountSettingStore';
import { useAuthStore } from '../store/authStore';
import { useMasterOperatorListStore } from '../store/masterOperatorListStore';
import { usePopupStore } from '../store/popupStore';
import SearchSelect, { Option } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	widthStyle?: string;
	op: Option | null;
	setOp: (option: Option | null) => void;
	required?: boolean;
	errorText?: string;
};

export function ParentOperatorSection({ widthStyle, op, setOp, required, errorText }: Props) {
	const { initOpList, options } = useMasterOperatorListStore();
	const { role } = useAccountSettingStore();
	const { authState } = useAuthStore();
	const { openPopup } = usePopupStore();
	const { t } = useTranslation();

	useEffect(() => {
		if (!role || !authState?.fpId) return;
		initOpList(openPopup, role, authState.fpId);
	}, [initOpList, openPopup, role, authState?.fpId]);

	useEffect(() => {
		if (role !== RoleEnum.Master || options.length !== 1) return;
		setOp(options[0]);
	}, [role, options, setOp]);

	return (
		<div className={`space-y-3 ${widthStyle || 'w-[200px]'}`}>
			<SectionTitle content={t('Master')} required={required} />
			<SearchSelect
				widthStyle="w-full"
				label={t('search.Select_Master')}
				option={op}
				setOption={setOp}
				options={options}
				errorText={errorText}
			/>
		</div>
	);
}

export default ParentOperatorSection;
