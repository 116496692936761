import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

type Props = {
	isAll?: boolean;
	loading: boolean;
	download: () => void;
};

export function DownloadExcelButton({ isAll = false, loading = false, download }: Props) {
	const { t } = useTranslation();

	return (
		<LoadingButton variant="contained" onClick={download} loading={loading}>
			<DownloadIcon className="mr-2" />
			{t(isAll ? 'reportTable.Export_All_Excel' : 'reportTable.Export_Excel')}
		</LoadingButton>
	);
}

export default DownloadExcelButton;
