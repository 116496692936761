export const getGameListMock = {
	gameList: [
		{ gameCode: '0', gameName: 'Funky Lobby' },
		{ gameCode: '10211', gameName: 'Captain Money' },
		{ gameCode: '10212', gameName: 'Inferno Sea' },
		{ gameCode: '10213', gameName: 'Narcos' },
		{ gameCode: '10214', gameName: 'Frontier Chaos' },
		{ gameCode: '10215', gameName: 'Frontier War' },
		{ gameCode: '10216', gameName: 'Buffalo Thunder' },
		{ gameCode: '10221', gameName: 'SkullBingo' },
		{ gameCode: '10222', gameName: 'Football Star' },
		{ gameCode: '10261', gameName: 'Golden Dynasty' },
		{ gameCode: '102610', gameName: 'Football Champion' },
		{ gameCode: '102611', gameName: 'Sparta' },
		{ gameCode: '102612', gameName: 'Poker Slam' },
		{ gameCode: '102613', gameName: 'Fortune Goddess' },
		{ gameCode: '102614', gameName: 'FaFaFa2' },
		{ gameCode: '102615', gameName: 'Southern Queen' },
		{ gameCode: '102617', gameName: 'Ji Ji Ji' },
		{ gameCode: '102618', gameName: 'Eros' },
		{ gameCode: '102619', gameName: 'White Lion' },
		{ gameCode: '10262', gameName: 'CaiShens Fortune' },
		{ gameCode: '102620', gameName: 'Highway King' },
		{ gameCode: '102621', gameName: "Captain's Treasure" },
		{ gameCode: '102622', gameName: '5 Dragons Legend' },
		{ gameCode: '102623', gameName: 'Monkey King' },
		{ gameCode: '102628', gameName: 'Ice Land' },
		{ gameCode: '102629', gameName: 'Great Blue' },
		{ gameCode: '10263', gameName: 'Lubu' },
		{ gameCode: '102630', gameName: 'Safari Heat' },
		{ gameCode: '102633', gameName: 'Taj Mahal' },
		{ gameCode: '102634', gameName: 'Thai Paradise' },
		{ gameCode: '102635', gameName: 'Roma' },
		{ gameCode: '10264', gameName: 'Thunder Feng Shen' },
		{ gameCode: '10265', gameName: 'Huga' },
		{ gameCode: '10266', gameName: 'FaFaFa' },
		{ gameCode: '10267', gameName: 'ShuiHuHeroes' },
		{ gameCode: '10268', gameName: 'Hawaii' },
		{ gameCode: '10269', gameName: 'Peony Beauty' },
		// { gameCode: '10281', gameName: 'Angel & Devil' },
		{ gameCode: '10291', gameName: 'Golden Crab' },
		{ gameCode: '110211', gameName: 'Cai Shen 88' },
		{ gameCode: '110212', gameName: 'Fortune Dragon' },
		{ gameCode: '110213', gameName: 'Calabash Brothers' },
		{ gameCode: '110214', gameName: 'The Phoenix from the Flames' },
		{ gameCode: '110215', gameName: 'Colorful Mermaid' },
		{ gameCode: '110216', gameName: 'Nezha' },
		{ gameCode: '110261', gameName: '5 Dragons' },
		{ gameCode: '1102610', gameName: 'Dance Party 2' },
		{ gameCode: '1102611', gameName: 'Lush Lotus' },
		{ gameCode: '1102612', gameName: 'Gems Wheel' },
		{ gameCode: '1102613', gameName: '88 Fortunes' },
		{ gameCode: '1102614', gameName: 'Luck Twin Stars' },
		{ gameCode: '1102615', gameName: 'Golden Toad' },
		{ gameCode: '1102616', gameName: 'Lucky Animal' },
		{ gameCode: '1102617', gameName: 'Magic Halloween' },
		{ gameCode: '1102618', gameName: 'Mid-Autumn Night' },
		{ gameCode: '1102619', gameName: 'Money Comes' },
		{ gameCode: '110262', gameName: 'Fortune Diamonds' },
		{ gameCode: '1102620', gameName: 'Tarzan' },
		{ gameCode: '1102621', gameName: 'Wolves Legend' },
		{ gameCode: '1102622', gameName: 'Caribbean Pirates' },
		{ gameCode: '1102623', gameName: 'Prosperous Year' },
		{ gameCode: '1102624', gameName: 'Lantern Festival' },
		{ gameCode: '1102625', gameName: 'Cleopatra' },
		{ gameCode: '1102626', gameName: 'Dragon Dance' },
		{ gameCode: '1102627', gameName: 'Fortune Diamonds 2' },
		{ gameCode: '1102628', gameName: 'Summer Fruits' },
		{ gameCode: '1102629', gameName: 'Fortunate Caishen' },
		{ gameCode: '110263', gameName: 'Classic Fruit' },
		{ gameCode: '1102630', gameName: 'Summer Neon' },
		{ gameCode: '1102631', gameName: 'Fortune brings wealth' },
		{ gameCode: '1102632', gameName: 'Lu\u00A0Lu Fa' },
		{ gameCode: '1102633', gameName: 'Spring Festival' },
		{ gameCode: '1102634', gameName: 'Womens Day' },
		{ gameCode: '1102635', gameName: 'Mountain Lord and Sea Lord' },
		{ gameCode: '1102636', gameName: 'Ba Na Hills' },
		{ gameCode: '1102637', gameName: 'Swing DJ' },
		{ gameCode: '1102638', gameName: 'The Five Elements' },
		{ gameCode: '1102639', gameName: 'Ao Dai the Beauty' },
		{ gameCode: '110264', gameName: 'Fa Fa Fa' },
		{ gameCode: '1102640', gameName: 'Gold Choice' },
		{ gameCode: '1102641', gameName: 'Dancing Drum' },
		{ gameCode: '1102642', gameName: 'Diamond Eternity' },
		{ gameCode: '1102643', gameName: 'Flourishing Peony' },
		{ gameCode: '1102644', gameName: 'Floating Market' },
		{ gameCode: '110265', gameName: 'Super Neon' },
		{ gameCode: '110266', gameName: 'Lucky Gems' },
		{ gameCode: '110267', gameName: 'Treasure Of Caishen' },
		{ gameCode: '110268', gameName: 'Candy Rhapsody' },
		{ gameCode: '110269', gameName: 'Dance Party' },
		{ gameCode: '1302601', gameName: 'Lucky Wheel' },
		{ gameCode: '1302602', gameName: 'Mini Poker' },
		{ gameCode: '1302603', gameName: 'GTM Diamond' },
		{ gameCode: '1302604', gameName: 'Mini Tree' },
		{ gameCode: '1302801', gameName: 'Fish Prawn Crab' },
		{ gameCode: '1302802', gameName: 'Hi-Lo' },
		{ gameCode: '20261', gameName: 'Bar Super' },
		{ gameCode: '202610', gameName: 'Excited Slot 3D 1000X' },
		{ gameCode: '202611', gameName: 'Fox Spirit' },
		{ gameCode: '202612', gameName: 'Cherry Blossom Samurai' },
		{ gameCode: '202613', gameName: 'BANANA' },
		{ gameCode: '202614', gameName: 'ORC' },
		{ gameCode: '202615', gameName: 'The Circus Night' },
		{ gameCode: '202616', gameName: 'Digging Gold' },
		{ gameCode: '202617', gameName: 'Tattoos' },
		{ gameCode: '202618', gameName: 'DJ QUEEN' },
		{ gameCode: '202619', gameName: 'Angel & Devil' },
		{ gameCode: '20262', gameName: 'Long Long Long III' },
		{ gameCode: '202620', gameName: 'Legend of Chu&Han' },
		{ gameCode: '202621', gameName: 'Civilization' },
		{ gameCode: '202622', gameName: 'Alchemy Magic' },
		{ gameCode: '202623', gameName: 'True Love Campus' },
		{ gameCode: '202624', gameName: 'Wudang Zhenwu Emperor' },
		{ gameCode: '202625', gameName: 'Excited Slot 3D' },
		{ gameCode: '202626', gameName: 'Western Revolver' },
		{ gameCode: '202627', gameName: 'Niu B' },
		{ gameCode: '20263', gameName: 'Classic Fruit Machine' },
		{ gameCode: '20264', gameName: 'Maid' },
		{ gameCode: '20265', gameName: "Pi-Xiu's treasure house" },
		{ gameCode: '20266', gameName: 'Kitchen God Welcome to New Year' },
		{ gameCode: '20267', gameName: 'Frozen Carnival' },
		{ gameCode: '20268', gameName: 'The Dragon and Chinese Qiling' },
		{ gameCode: '20269', gameName: 'Pet Farm' },
		{ gameCode: '40211', gameName: '3 Gods Fishing' },
		{ gameCode: '40212', gameName: 'Ocean Lord' },
		{ gameCode: '40261', gameName: 'Get Money' },
		{ gameCode: '40262', gameName: 'Phoenix' },
		{ gameCode: '40263', gameName: 'LU LING QI' },
		{ gameCode: '50251', gameName: 'Keno' },
		{ gameCode: '50252', gameName: 'Atom' },
		{ gameCode: '50253', gameName: 'RNGWAR' },
		{ gameCode: '50254', gameName: 'Number Game' },
		{ gameCode: '50255', gameName: 'KenoWar' },
		{ gameCode: '50256', gameName: 'KenoSoccer' },
		{ gameCode: '50257', gameName: 'AtomWar' },
		{ gameCode: '602813', gameName: 'Football Strike' },
		{ gameCode: '602816', gameName: 'Lucky Wheel' },
		{ gameCode: '602817', gameName: 'Cash Or Crash' },
		{ gameCode: '602819', gameName: 'Fish Prawn Crab' },
		{ gameCode: '602821', gameName: 'Virtual Baccarat' },
		{ gameCode: '602822', gameName: 'Caribbean Saga' },
		{ gameCode: '602823', gameName: 'Football Strike Mini' },
		{ gameCode: '602824', gameName: 'Baccarat Babes' },
		{ gameCode: '602825', gameName: 'Basketball Strike' },
		{ gameCode: '602826', gameName: 'FanTan' },
		{ gameCode: '602827', gameName: 'Speed Lotto' },
		{ gameCode: '602828', gameName: 'Lucky Lotto' },
		{ gameCode: '602829', gameName: 'Virtual SicBo' },
		{ gameCode: '602830', gameName: 'Virtual Roulette' },
		{ gameCode: '602831', gameName: 'Thai Hi Lo' },
		{ gameCode: '602832', gameName: 'Tai Xiu' },
		{ gameCode: '602833', gameName: 'Bau Cua' },
		{ gameCode: '602834', gameName: 'Football Fever' },
		{ gameCode: '602835', gameName: 'Speed Lotto' },
		{ gameCode: '602837', gameName: 'BeLangKai' },
		{ gameCode: '602838', gameName: 'Virtual Domino QQ' },
		{ gameCode: '602839', gameName: 'Galaxy Attack' },
		{ gameCode: '802101', gameName: 'Heart Of Ocean' },
		{ gameCode: '802201', gameName: 'SEDIE' },
		{ gameCode: '802302', gameName: 'Pok Deng' },
		{ gameCode: '802303', gameName: 'Domino QQ' },
		{ gameCode: '802304', gameName: 'Domino Gaple Bet' },
		{ gameCode: '802305', gameName: 'Capsa Susun' },
		{ gameCode: '802501', gameName: 'Lucky 5' },
		{ gameCode: '802502', gameName: 'Happy 10' },
		{ gameCode: '802503', gameName: 'PK10' },
		{ gameCode: '802601', gameName: '777 Blazing' },
		{ gameCode: '802602', gameName: 'Three Gnomes' },
		{ gameCode: '802603', gameName: 'Pinata' },
		{ gameCode: '802604', gameName: 'Zombie Killer' },
		{ gameCode: '802605', gameName: 'Ghost Pirate' },
		{ gameCode: '802606', gameName: 'EURO CUP' },
		{ gameCode: '802607', gameName: 'Jalapeno' },
	],
	returnCode: '0',
	message: '',
};

export default getGameListMock;
