export const searchWinLoseReportByGameMock = {
	data: {
		operatorName: 'Sylph',
		timeZone: '0',
		gameCurrencyList: [
			{
				gameName: 'Football Strike',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '2',
						validTurnover: '10.00',
						playerWinLose: '3.55',
						operatorWinLose: '-3.55',
					},
					{
						currency: 'MYR',
						betCount: '1',
						validTurnover: '5.00',
						playerWinLose: '4.75',
						operatorWinLose: '-4.75',
					},
				],
			},
			{
				gameName: 'Tai Xiu',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '1',
						validTurnover: '5.00',
						playerWinLose: '4.75',
						operatorWinLose: '-4.75',
					},
				],
			},
			{
				gameName: 's',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '18',
						validTurnover: '39.50',
						playerWinLose: '10.50',
						operatorWinLose: '-10.50',
					},
				],
			},
			{
				gameName: 'Football Strike Mini',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '1',
						validTurnover: '5.00',
						playerWinLose: '4.75',
						operatorWinLose: '-4.75',
					},
				],
			},
			{
				gameName: 'Virtual Roulette',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '3',
						validTurnover: '720.00',
						playerWinLose: '-120.00',
						operatorWinLose: '120.00',
					},
				],
			},
			{
				gameName: 'Cash Or Crash',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '1',
						validTurnover: '8.00',
						playerWinLose: '6.24',
						operatorWinLose: '-6.24',
					},
				],
			},
			{
				gameName: 'Bau Cua',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '1',
						validTurnover: '1.00',
						playerWinLose: '-1.00',
						operatorWinLose: '1.00',
					},
				],
			},
			{
				gameName: 'Virtual Baccarat',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '5',
						validTurnover: '5.00',
						playerWinLose: '8.95',
						operatorWinLose: '-8.95',
					},
				],
			},
			{
				gameName: 'BeLangKai',
				totalActivePlayer: '20',
				totalBetCount: '34',
				winLoseList: [
					{
						currency: 'USD',
						betCount: '1',
						validTurnover: '1.00',
						playerWinLose: '-1.00',
						operatorWinLose: '1.00',
					},
				],
			},
		],
	},
};

export default searchWinLoseReportByGameMock;
