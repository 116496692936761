export const searchMasterOperatorMock = {
	operatorList: [
		{
			fpId: '1',
			name: 'Sylph',
			isMasterOperator: true,
			parentOperator: 0,
			resellerId: 0,
			apiWhiteList: ['124', '23'],
		},
		{
			fpId: '17',
			name: 's',
			isMasterOperator: true,
			parentOperator: 0,
			resellerId: 0,
			apiWhiteList: ['32', '12'],
		},
	],
};

export default searchMasterOperatorMock;
