export const getSubLineRequisitionsMock = {
	currentPage: 1,
	pageCount: 10,
	pageSize: 10,
	rowCount: 100,
	data: [
		{
			requisitionId: '1',
			requestOn: '2022-10-07T07:59:55.999Z',
			operatorName: 'string',
			serverUrl: 'string',
			apiWhiteList: ['string', 'string', 'string'],
			requestStatus: 'Approved',
			actionBy: 'string',
			fpId: 'string',
			parentOperatorId: 'string',
			parentOperatorName: 'string',
			userAgent: '128-allllll',
			authentication: 'WwYgsJ9l-6XbE-3IkW-JWMvDKuhcrakekF7',
		},
	],
};

export default getSubLineRequisitionsMock;
