import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'react-i18next';

export const currencyCol = (t: TFunction): GridColDef => ({
	field: 'currency',
	headerName: t('Currency'),
	type: 'string',
	flex: 1,
	minWidth: 80,
	sortable: false,
});

export const betCountCol = (t: TFunction): GridColDef => ({
	field: 'betCount',
	headerName: t('reportTable.BetCount'),
	type: 'number',
	flex: 1,
	minWidth: 90,
	sortable: false,
});

export const turnoverCol = (t: TFunction): GridColDef => ({
	field: 'turnover',
	headerName: t('reportTable.Turnover'),
	type: 'number',
	flex: 2,
	minWidth: 90,
	sortable: false,
});

export const validTurnoverCol = (t: TFunction): GridColDef => ({
	field: 'validTurnover',
	headerName: t('reportTable.ValidTurnover'),
	type: 'number',
	flex: 2,
	minWidth: 120,
	sortable: false,
});

export const playerWinLoseCol = (t: TFunction): GridColDef => ({
	field: 'playerWinLose',
	headerName: t('reportTable.PlayerWinLose'),
	type: 'number',
	flex: 2,
	minWidth: 155,
	sortable: false,
});

export const operatorWinLoseCol = (t: TFunction): GridColDef => ({
	field: 'operatorWinLose',
	headerName: t('reportTable.OperatorWinLose'),
	type: 'number',
	flex: 2,
	minWidth: 155,
	sortable: false,
});

export const isTestAccountCol = (t: TFunction): GridColDef => ({
	field: 'isTestAccount',
	headerName: t('reportTable.IsTestAccount'),
	type: 'boolean',
	flex: 1,
	minWidth: 120,
	sortable: false,
	// valueGetter: (value) => (value ? t('reportTable.Yes') : t('reportTable.No')),
});

export const playerIdCol = (t: TFunction): GridColDef => ({
	field: 'playerId',
	headerName: t('PlayerId'),
	type: 'string',
	flex: 2,
	minWidth: 300,
	sortable: false,
});

export const opNameCol = (t: TFunction): GridColDef => ({
	field: 'operatorName',
	headerName: t('Operator'),
	type: 'string',
	flex: 2,
	minWidth: 120,
	sortable: false,
});

export const betTimeCol = (t: TFunction): GridColDef => ({
	field: 'betTime',
	headerName: t('reportTable.BetTime'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const refNoCol = (t: TFunction): GridColDef => ({
	field: 'refNo',
	headerName: t('reportTable.ReferenceNumber'),
	type: 'string',
	flex: 3,
	minWidth: 300,
	sortable: false,
	cellClassName: 'text-left whitespace-pre-line break-all',
});

export const gameNameCol = (t: TFunction): GridColDef => ({
	field: 'gameName',
	headerName: t('reportTable.GameName'),
	type: 'string',
	flex: 2,
	minWidth: 230,
	sortable: false,
});

export const gameCodeCol = (t: TFunction): GridColDef => ({
	field: 'gameCode',
	headerName: t('reportTable.GameCode'),
	type: 'string',
	flex: 1,
	minWidth: 100,
	sortable: false,
});

export const resultCol = (t: TFunction): GridColDef => ({
	field: 'result',
	headerName: t('reportTable.Result'),
	type: 'string',
	flex: 1,
	minWidth: 70,
	sortable: false,
});

export const statementDateCol = (t: TFunction): GridColDef => ({
	field: 'statementDate',
	headerName: t('reportTable.StatementDate'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const betStatusCol = (t: TFunction): GridColDef => ({
	field: 'betStatus',
	headerName: t('reportTable.BetStatus'),
	type: 'string',
	flex: 2,
	minWidth: 80,
	sortable: false,
});

export const betAmountCol = (t: TFunction): GridColDef => ({
	field: 'betAmount',
	headerName: t('reportTable.Amount'),
	type: 'number',
	flex: 2,
	minWidth: 80,
	sortable: false,
});

export const winLossCol = (t: TFunction): GridColDef => ({
	field: 'winLoss',
	headerName: t('reportTable.PlayerWinLose'),
	type: 'number',
	flex: 2,
	minWidth: 155,
	sortable: false,
});

export const txTimeCol = (t: TFunction): GridColDef => ({
	field: 'txTime',
	headerName: t('reportTable.TxTime'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const txSerialIdCol = (t: TFunction): GridColDef => ({
	field: 'txSerialId',
	headerName: t('reportTable.TxSerialId'),
	type: 'string',
	flex: 2,
	minWidth: 80,
	sortable: false,
});

export const txIdCol = (t: TFunction): GridColDef => ({
	field: 'txId',
	headerName: t('reportTable.TxId'),
	type: 'string',
	flex: 2,
	minWidth: 300,
	sortable: false,
	cellClassName: 'text-left whitespace-pre-line break-all',
});

export const txTypeCol = (t: TFunction): GridColDef => ({
	field: 'txType',
	headerName: t('reportTable.TxType'),
	type: 'string',
	flex: 2,
	minWidth: 130,
	sortable: false,
});

export const txAmountCol = (t: TFunction): GridColDef => ({
	field: 'amount',
	headerName: t('reportTable.TxAmount'),
	type: 'number',
	flex: 2,
	minWidth: 155,
	sortable: false,
});

export const txStatusCol = (t: TFunction): GridColDef => ({
	field: 'status',
	headerName: t('reportTable.TxStatus'),
	type: 'string',
	flex: 2,
	minWidth: 140,
	sortable: false,
});

export const winloseColumns = [currencyCol, betCountCol, validTurnoverCol, operatorWinLoseCol, playerWinLoseCol];

export const winlosePlayerColumns = [
	playerIdCol,
	currencyCol,
	betCountCol,
	validTurnoverCol,
	playerWinLoseCol,
	operatorWinLoseCol,
	isTestAccountCol,
];

export const sumTransactionReportColumns = [
	currencyCol,
	betCountCol,
	validTurnoverCol,
	playerWinLoseCol,
	operatorWinLoseCol
];

export const restTransactionReportColumns = [
	betTimeCol,
	currencyCol,
	gameCodeCol,
	gameNameCol,
	validTurnoverCol,
	turnoverCol,
	playerWinLoseCol,
	operatorWinLoseCol,
	resultCol,
	isTestAccountCol,
];

export const betColumns = [
	betTimeCol,
	refNoCol,
	currencyCol,
	gameCodeCol,
	validTurnoverCol,
	betAmountCol,
	winLossCol,
	betStatusCol,
	statementDateCol,
];

export const txTransactionHistoryColumns = [
	txIdCol,
	txTimeCol,
	txSerialIdCol,
	txTypeCol,
	currencyCol,
	txAmountCol,
	txStatusCol,
];
