export enum PopupEnum {
	CreateSuccess = 'CreateSuccess',
	CreateFailed = 'CreateFailed',
	UpdateSuccess = 'UpdateSuccess',
	UpdateFailed = 'UpdateFailed',
	NewPasswordNotMatch = 'NewPasswordNotMatch',
	SamePassword = 'SamePassword',
	PasswordNotCorrect = 'PasswordNotCorrect',
	PasswordRuleNotMapping = 'PasswordRuleNotMapping',
	InvalidInput = 'InvalidInput',
	Unauthorized = 'Unauthorized',
	Forbidden = 'Forbidden',
	InternalServerError = 'InternalServerError',
	OverSearchDay = 'OverSearchDay',
	ResultNotFound = 'ResultNotFound',
	ResultExportFailed = 'ResultExportFailed',
	Loading = 'Loading',
	SystemRoleTypeNotMatch = 'SystemRoleTypeNotMatch',
	CustomMessage = 'CustomMessage',
	FetchError = 'FetchError',
	OperatorNameExisted = 'OperatorNameExisted',
	OperatorNameInvalid = 'OperatorNameInvalid',
	UrlInvalid = 'UrlInvalid',
	WhiteListInvalid = 'WhiteListInvalid',
	DuplicateId = 'DuplicateId',
}

export default PopupEnum;
