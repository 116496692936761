import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	comfirmAction: () => void;
	children: ReactNode;
};

export const ConfirmActionPopup = ({ comfirmAction, open, setOpen, children }: Props) => {
	const { t } = useTranslation();
	const handleAction = () => {
		comfirmAction();
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} fullWidth>
			<DialogTitle>{t('ConfirmPopupTitle')}</DialogTitle>
			<DialogContent className="space-y-2 flex">
				<Stack padding={1}>{children}</Stack>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => setOpen(false)}>{t('ConfirmCancelButton')}</Button>
				<Button onClick={handleAction}>{t('ConfirmSubmitButton')}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmActionPopup;
