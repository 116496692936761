import { GetAccountSettingResponse } from '../../models';

export const getAccountSettingMock: GetAccountSettingResponse = {
	role: 'Admin',
	// role: 'Reseller',
	// role: 'SmlMaster',
	// role: 'Manager',
	accountRole: 'Admin',
	// accountRole: 'Reseller',
	// accountRole: 'Master',
	// accountRole: 'Manager',
	accountType: 'All',
	// accountType: 'Sml',
	// accountType: 'Tr',
	returnCode: '0',
	message: '',
};

export default getAccountSettingMock;
