import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Popup from './components/common/Popup';
import SideBar from './components/SideBar';

const Layout = () => {
	return (
		<div className="flex flex-col h-screen overflow-hidden">
			<Header />
			<Popup />
			<div className="flex-1 flex overflow-hidden">
				<SideBar />
				<div className="p-8 flex-1 mx-auto overflow-auto">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default Layout;
