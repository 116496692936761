import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { Option } from '../components/common/SearchSelect';
import PopupEnum from '../enum/popupEnum';
import api from '../api';
import { GameData } from '../models';

export interface IGameListStore {
	gameList: GameData[];
	initGameList: (openPopup: (popup: PopupEnum) => void) => void;
	transferToOptions: () => Option[] | [];
	clear: () => void;
}

const key = 'gameListStore';

export const useGameListStore = create<IGameListStore>()(
	devtools(
		persist(
			(set, get) => ({
				gameList: [],
				initGameList: async (openPopup) => {
					if (get().gameList.length > 0) return;
					try {
						const { gameList } = await api.getGameList();
						set((state) => ({ ...state, gameList }));
					} catch (error) {
						openPopup(PopupEnum.InternalServerError);
					}
				},
				transferToOptions: () => {
					const result = get().gameList.map((item) => ({ id: item.gameCode, label: item.gameName }));
					return result;
				},
				clear: () => set({ gameList: [] }),
			}),
			{
				name: key,
				getStorage: () => sessionStorage,
			}
		)
	)
);
