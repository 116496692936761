import { LoadingButton } from '@mui/lab';
import { Button, DialogContentText, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';
import AccountTypeSection from '../components/AccountTypeSection';
import { ConfirmActionPopup } from '../components/common/ConfirmPopup';
import { EnumSelectSection } from '../components/common/EnumSelectSection';
import { InputSection } from '../components/common/InputSection';
import PageTitle from '../components/common/PageTitle';
import { Option } from '../components/common/SearchSelect';
import SectionTitle from '../components/common/SectionTitle';
import { OperatorSection } from '../components/OperatorSection';
import StatusSection, { getStatusOption } from '../components/StatusSection';
import AccountTypeEnum from '../enum/accountTypeEnum';
import accountTypeList from '../enum/accountTypeList';
import PopupEnum from '../enum/popupEnum';
import RoleEnum from '../enum/roleEnum';
import StatusEnum from '../enum/statusEnum';
import { useError } from '../hook/useError';
import { Account } from '../models';
import { useAccountSettingStore } from '../store/accountSettingStore';
import { useOperatorListStore } from '../store/operatorListStore';
import { usePopupStore } from '../store/popupStore';
import { convertToOption } from '../utils/option';

type Props = {};

export const MemberUpdatePage = (props: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { getOpOptionByName } = useOperatorListStore();
	const { accountType, role } = useAccountSettingStore();
	const [accountId, setAccountId] = useState<string>('');
	const [accountName, setAccountName] = useState<string>('');
	const [accountTypeOption, setAccountType] = useState<Option | null>(null);
	const [roleOption, setRole] = useState<Option<RoleEnum> | null>(null);
	const [status, setStatus] = useState<Option | null>(null);
	const [op, setOp] = useState<Option | null>(null);
	const [contactPhone, setContactPhone] = useState<string>('');
	const [mobilePhone, setMobilePhone] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [remark, setRemark] = useState<string>('');
	const [confirmPopupOpen, setConfirmPopupOpen] = useState<boolean>(false);
	const { openPopup, setPopup } = usePopupStore();
	const { handleError } = useError();

	const goBack = () => navigate(-1);

	const handleResetPassword = async () => {
		const res = await api.resetPassword({ accountId });
		setPopup(PopupEnum.CustomMessage, `${t('popup.NewPassword')}${res.newPassword}`);
	};

	const handleUpdate = async () => {
		try {
			if (!accountId || !accountName || !accountTypeOption || !roleOption || !status || !op)
				throw PopupEnum.InvalidInput;

			openPopup(PopupEnum.Loading);
			await api.updateAccountProfile({
				accountId,
				accountName,
				status: status.id,
				role: roleOption.id,
				accountType: accountTypeOption.id,
				operatorId: op.id,
				email,
				contactPhone,
				mobilePhone,
				remark,
			});
			openPopup(PopupEnum.UpdateSuccess);
			goBack();
		} catch (error: any | PopupEnum) {
			handleError(error);
		}
	};

	useEffect(() => {
		if (!location.state) navigate('/');
		const account = location.state as Account;
		setAccountId(account.accountId);
		setAccountName(account.accountName);
		setAccountType(accountTypeList.find((item) => item.id === account.accountType) || null);
		setRole(convertToOption(account.role as RoleEnum));
		setStatus(getStatusOption(t, account.status as StatusEnum));
		setOp(getOpOptionByName(account.agentName));
		setContactPhone(account.contactPhone);
		setMobilePhone(account.contactPhone);
		setEmail(account.email);
		setRemark(account.remark);
	}, [getOpOptionByName, location.state, navigate, t]);

	return (
		<div className="space-y-4 lg:w-[705px] w-auto mx-auto">
			<ConfirmActionPopup open={confirmPopupOpen} setOpen={setConfirmPopupOpen} comfirmAction={handleResetPassword}>
				<DialogContentText>{t('ConfirmPopupMessage')}</DialogContentText>
			</ConfirmActionPopup>
			<PageTitle content={t('management.MemberUpdate.Title')} />
			<Paper className="w-full grid grid-cols-12 gap-3 mt-4 p-8 bg-gray-50">
				<InputSection
					widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
					title={t('management.MemberForm.AccountId')}
					value={accountId}
					disabled
					required
				/>
				<InputSection
					widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
					title={t('management.MemberForm.AccountName')}
					value={accountName}
					setValue={setAccountName}
					required
				/>
				<StatusSection
					widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
					status={status}
					setStatus={setStatus}
				/>
				<div className="space-y-3 col-span-12 md:col-span-6 lg:col-span-3">
					<SectionTitle content={t('management.MemberForm.Password')} />
					<LoadingButton className="w-full" variant="blueGradient" onClick={() => setConfirmPopupOpen(true)}>
						{t('management.MemberUpdate.ResetPassword')}
					</LoadingButton>
				</div>
				{accountType && (
					<AccountTypeSection
						widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
						option={accountTypeOption}
						setOption={setAccountType}
						options={accountTypeList.filter(
							(option) => accountType === AccountTypeEnum.All || option.id === accountType
						)}
						required
					/>
				)}
				{role && (
					<EnumSelectSection
						title={t('management.MemberForm.RoleType')}
						label={t('management.MemberForm.Select')}
						widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
						option={roleOption}
						setOption={setRole}
						optionEnum={role === 'Admin' ? RoleEnum : role === 'Manager' ? {} : { Manager: 'Manager' }}
						required
					/>
				)}

				<OperatorSection
					widthStyle="w-full col-span-12 lg:col-span-6"
					op={op}
					setOp={setOp}
					filterRole={roleOption?.id}
					required
				/>

				<InputSection
					title={t('management.MemberForm.ContactPhone')}
					widthStyle="w-full col-span-12 md:col-span-6"
					value={contactPhone}
					setValue={setContactPhone}
				/>
				<InputSection
					title={t('management.MemberForm.MobilePhone')}
					widthStyle="w-full col-span-12 md:col-span-6"
					value={mobilePhone}
					setValue={setMobilePhone}
				/>
				<InputSection
					title={t('management.MemberForm.Email')}
					widthStyle="w-full col-span-12"
					value={email}
					setValue={setEmail}
				/>

				<InputSection
					title={t('management.MemberForm.Remark')}
					widthStyle="w-full col-span-12"
					value={remark}
					setValue={setRemark}
				/>
				<div className="pt-8 col-span-12 flex justify-center flex-wrap gap-4">
					<Button variant="redGradient" className="color-[#ae0000]" onClick={goBack}>
						{t('management.MemberUpdate.BackButton')}
					</Button>
					<LoadingButton variant="gradient" onClick={handleUpdate}>
						{t('management.MemberUpdate.SubmitButton')}
					</LoadingButton>
				</div>
			</Paper>
		</div>
	);
};

export default MemberUpdatePage;
