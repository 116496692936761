import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

type Props = {
	date: Date | null;
	setDate: (value: Date | null) => void;
	label: string;
};

export const DateSelect = ({ date, setDate, label }: Props) => {
	return (
		<DatePicker
			label={label}
			value={date}
			onChange={(value: Date | null) => setDate(value)}
			inputFormat={'yyyy-MM-dd'}
			mask="____-__-__"
			renderInput={(params: any) => <TextField {...params} />}
		/>
	);
};

export default DateSelect;
