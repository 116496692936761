import { Pagination } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { CheckboxSection } from '../components/CheckboxSection';
import { DateSelectSection } from '../components/DateSelectSection';
import DownloadExcelButton from '../components/DownloadExcelButton';
import { GameSection } from '../components/GameSection';
import { OperatorSection } from '../components/OperatorSection';
import { winlosePlayerColumns } from '../components/ReportTableColumns';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import { TableHeader } from '../components/TableHeader';
import PageTitle from '../components/common/PageTitle';
import { Option, optionAll } from '../components/common/SearchSelect';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { useFile } from '../hook/useFile';
import { usePage } from '../hook/usePage';
import { useRequestId } from '../hook/useRequestId';
import { DownloadPlayerWinLoseRequest, SearchWinLoseReportByPlayerRequest, WinLoseOperatorPlayerData } from '../models';
import { usePopupStore } from '../store/popupStore';
import { checkDateRange, formatDate } from '../utils/date';

type Props = {};

export const WinLosePlayerPage: React.FC<Props> = () => {
	const { t } = useTranslation();
	const [data, setData] = useState<WinLoseOperatorPlayerData | null>(null);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [op, setOp] = useState<Option | null>(null);
	const [game, setGame] = useState<Option | null>(optionAll);
	const [includeTestAccount, setIncludeTestAccount] = useState<boolean>(false);
	const { requestId, updateSearch } = useRequestId(handleSearch);
	const [loading, setLoading] = useState(false);
	const { handleFile } = useFile();
	const fileRequest = useRef<DownloadPlayerWinLoseRequest>();
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();
	const { currentPage, handlePage, pageSize, pageCount } = usePage();

	async function handleSearch(page: number, searchId: string = requestId) {
		try {
			if (!startDate || !endDate || !game || !op) throw PopupEnum.InvalidInput;
			if (checkDateRange(startDate, endDate, 31)) throw PopupEnum.OverSearchDay;
			openPopup(PopupEnum.Loading);

			const req: SearchWinLoseReportByPlayerRequest = {
				startDate: formatDate(startDate),
				endDate: formatDate(endDate),
				gameCode: game.id,
				fpId: op.id,
				includeTestAccount,
				currentPage: page,
				pageSize,
				searchId,
			};
			const res = await api.searchWinLoseReportByPlayer(req);
			if (!res.rowCount) throw PopupEnum.ResultNotFound;
			handlePage(res.currentPage, res.pageCount);
			fileRequest.current = req;
			setData(res.winLoseReportByPlayerInfo);
			closePopup();
		} catch (error: any | PopupEnum) {
			setData(null);
			handleError(error);
		}
	}

	const downloadPlayerWinLose = async () => {
		try {
			setLoading(true);
			if (!fileRequest.current) throw PopupEnum.InvalidInput;
			const [excelFile, disposition] = await api.downloadPlayerWinLose(fileRequest.current);
			handleFile(excelFile, disposition);
		} catch (error) {
			handleError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="space-y-4">
			<PageTitle content={t('sidebar.report.winloseByPlayer')} />
			<SearchWrapper handleSearch={() => updateSearch()} showCurrencyHint>
				<DateSelectSection
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				<GameSection game={game} setGame={setGame} hasAll />
				<OperatorSection op={op} setOp={setOp} />

				<CheckboxSection
					isTestAccount={includeTestAccount}
					toggleTestAccount={() => setIncludeTestAccount((value) => !value)}
				/>
			</SearchWrapper>
			{!!data?.playerCurrencyList.length && (
				<>
					<div className="space-y-12">
						<div className="flex justify-end p-2">
							<DownloadExcelButton download={downloadPlayerWinLose} isAll loading={loading} />
						</div>
						<Table
							list={data.playerCurrencyList}
							columns={winlosePlayerColumns}
							components={{ Toolbar: () => <TableHeader info={data} /> }}
						/>
					</div>
					{!!currentPage && (
						<Pagination
							count={pageCount}
							color="primary"
							page={currentPage}
							onChange={(e, value) => value !== currentPage && handleSearch(value)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default WinLosePlayerPage;
