import { GetBetByRefNoResponse } from '../../models/GetBetByRefNoResponse';

export const getBetByRefNoMock: GetBetByRefNoResponse = {
	returnCode: '0',
	message: '',
	data: {
		statementDate: '1',
		betTime: '1',
		refNo: '112333333333333333333333333333333333333333333333333333333333333333333333333333333333',
		betStatus: '1',
		gameCode: '1',
		currency: '1',
		validTurnover: '1',
		betAmount: '1',
		winLoss: '1',
	},
};

export default getBetByRefNoMock;
