import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageEnum from '../enum/languageEnum';
import translation_EN from './resources/EN.json';
import translation_ZH_CN from './resources/ZH_CN.json';

export const resources = {
	[LanguageEnum.EN]: {
		translation: translation_EN,
	},
	[LanguageEnum.ZH_CN]: {
		translation: translation_ZH_CN,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: LanguageEnum.EN,
		fallbackLng: LanguageEnum.EN,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		detection: {
			caches: ['localStorage'],
		},
	});

export default i18n;
