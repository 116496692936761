import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../api';
import PopupEnum from '../enum/popupEnum';
import { Option } from './../components/common/SearchSelect';
import { RoleEnum } from './../enum/roleEnum';
import { OperatorRoleInfoData } from './../models/OperatorDataBase';

type OptionDictionary = {
	[key in RoleEnum]: Options;
};

export interface IOperatorListStore {
	opList: OperatorRoleInfoData[] | [];
	optionDictionary: OptionDictionary | null;
	initOpList: (openPopup: (popup: PopupEnum) => void) => void;
	getOpOptionByName: (name: string) => Option | null;
	clear: () => void;
}

type Options = Option[];

const key = 'useOperatorListStore';

const trasferToOption = ({ fpId, name }: { fpId: string; name: string }) => ({
	id: fpId,
	label: name,
});

export const useOperatorListStore = create<IOperatorListStore>()(
	devtools(
		persist(
			(set, get) => ({
				opList: [],
				optionDictionary: null,
				initOpList: async (openPopup) => {
					if (get().opList.length > 0) return;
					try {
						const { operatorList } = await api.getOperatorList();
						set((state) => ({ ...state, opList: operatorList }));
						const optionDictionary = operatorList.reduce(
							(result, { isReseller, isMasterOperator, ...rest }) => {
								const option = trasferToOption(rest);
								result.Admin.push(option);
								if (isReseller) {
									result.Reseller.push(option);
								} else if (isMasterOperator) {
									result.Master.push(option);
								} else result.Manager.push(option);
								return result;
							},
							{
								[RoleEnum.Admin]: [] as Options,
								[RoleEnum.Reseller]: [] as Options,
								[RoleEnum.Master]: [] as Options,
								[RoleEnum.Manager]: [] as Options,
							}
						);
						set((state) => ({ ...state, optionDictionary }));
					} catch (error) {
						console.log(error);
						openPopup(PopupEnum.InternalServerError);
					}
				},
				getOpOptionByName: (opName) => {
					if (get().opList.length) {
						const opInfo = get().opList.find((op) => op.name.toLocaleLowerCase() === opName.toLocaleLowerCase());

						if (opInfo) return trasferToOption(opInfo);
					}
					return null;
				},
				clear: () => set({ opList: [], optionDictionary: null }),
			}),
			{
				name: key,
				getStorage: () => sessionStorage,
			}
		)
	)
);
