import { useEffect, useState } from 'react';

const revokeUrl = (url: string) => {
	if (!url) return;
	window.URL.revokeObjectURL(url);
};

type Props = {
	handleFile: (file: File, disposition: string) => void;
};

export const useFile = (): Props => {
	const [fileUrl, setFileUrl] = useState<string>('');

	const handleFile = (file: any, disposition: string) => {
		const url = window.URL.createObjectURL(new Blob([file]));
		setFileUrl(url);
		const filename = disposition.split('filename=')[1].split(';')[0];
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	useEffect(() => {
		return () => {
			if (!fileUrl) return;
			revokeUrl(fileUrl);
		};
	}, [fileUrl]);

	return { handleFile };
};
