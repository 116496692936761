import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import SystemRoleEnum from '../enum/systemRoleEnum';

export type AuthState = {
	account: string;
	token: string;
	role: SystemRoleEnum;
	timeZone: string;
	loginDate: string;
	isDefaultPassword: boolean;
	fpId: string;
};

export interface IAuthStore {
	authState: AuthState | null;
	setAuthState: (authState: AuthState) => void;
	isIncludeRole: (roles?: SystemRoleEnum[]) => boolean;
	isIncludeFpId: (fpIds?: string[]) => boolean;
	isShowSummarize: () => boolean;
	clear: () => void;
}

export const useAuthStore = create<IAuthStore>()(
	devtools(
		persist(
			(set, get) => ({
				authState: null,
				setAuthState: (authState) => {
					set((state) => ({ ...state, authState }));
				},
				isIncludeRole: (roles) => {
					if (!roles) return true;
					const role = get().authState?.role;
					if (role) return roles.includes(role);

					return false;
				},
				isIncludeFpId: (fpIds) => {
					if (!fpIds) return true;
					const fpId = get().authState?.fpId;
					if (fpId) return fpIds.includes(fpId);

					return false;
				},
				isShowSummarize: () =>
					get().isIncludeRole([
						SystemRoleEnum.Admin,
						SystemRoleEnum.Reseller,
						SystemRoleEnum.TrMaster,
						SystemRoleEnum.SmlMaster,
					]),
				clear: () => set({ authState: null }),
			}),
			{
				name: 'authStore',
				getStorage: () => sessionStorage,
			}
		)
	)
);
