import { useTranslation } from 'react-i18next';
import CheckboxList, { CheckItem } from './common/CheckboxList';

type Props = {
	isTestAccount: boolean;
	toggleTestAccount: () => void;
	list?: CheckItem[];
};

export function CheckboxSection({ isTestAccount, toggleTestAccount, list = [] }: Props) {
	const { t } = useTranslation();

	return (
		<CheckboxList
			list={[
				{
					checked: isTestAccount,
					toggle: toggleTestAccount,
					text: t('search.Include_Test_Account'),
				},
				...list,
			]}
		/>
	);
}

export default CheckboxSection;
