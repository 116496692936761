import { Option } from '../components/common/SearchSelect';
import AccountTypeEnum from './accountTypeEnum';

export const accountTypeList: Option[] = [
	{ id: AccountTypeEnum.All, label: 'All' },
	{ id: AccountTypeEnum.Sml, label: 'Seamless' },
	{ id: AccountTypeEnum.Tr, label: 'Transfer' },
];

export default accountTypeList;
