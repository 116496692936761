export const searchWinLoseReportByGameProviderMock = {
	data: [
		{
			operatorName: 'a',
			gameProviderName: 'x',
			timeZone: '4',
			totalBetCount: '123',
			totalActivePlayer: '123',
			winLoseList: [
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
			],
		},
		{
			operatorName: 'a',
			gameProviderName: 'y',
			timeZone: '4',
			totalBetCount: '123',
			totalActivePlayer: '123',
			winLoseList: [
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
			],
		},
		{
			operatorName: 'b',
			gameProviderName: 'x',
			timeZone: '4',
			totalBetCount: '123',
			totalActivePlayer: '123',
			winLoseList: [
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
			],
		},
		{
			operatorName: 'b',
			gameProviderName: 'y',
			timeZone: '8',
			totalBetCount: '123',
			totalActivePlayer: '123',
			winLoseList: [
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
				{
					currency: 'MYR',
					betCount: '123',
					validTurnover: '123',
					playerWinLose: '123',
					operatorWinLose: '123',
				},
			],
		},
	],
};

export default searchWinLoseReportByGameProviderMock;
