import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DetailButton } from './DetailButton';

type Props = {
	children: ReactNode;
	buttonType?: 'contained' | 'icon';
};

export const DetailPopupSection = ({ children, buttonType = 'contained' }: Props) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<>
			<DetailButton buttonType={buttonType} onClick={() => setOpen(true)} />
			<Dialog open={open} onClose={() => setOpen(false)} fullWidth>
				<DialogTitle>{t('Detail')}</DialogTitle>
				<DialogContent className="space-y-2 flex">
					<Stack padding={1}>{children}</Stack>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DetailPopupSection;
