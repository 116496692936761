import create from 'zustand';
import api from '../api';
import SystemRoleEnum from '../enum/systemRoleEnum';
import { LoginRequest } from '../models';
import { formatDateTime } from '../utils/date';
import { useAccountSettingStore } from './accountSettingStore';
import { AuthState, useAuthStore } from './authStore';
import { useCurrencyListStore } from './currencyListStore';
import { useGameListStore } from './gameListStore';
import { useGameProviderListStore } from './gameProviderListStore';
import { useMasterOperatorListStore } from './masterOperatorListStore';
import { useOperatorListStore } from './operatorListStore';

export interface ILoginStore {
	login: (loginRequest: LoginRequest) => Promise<Boolean>;
	logout(): void;
}

export const useLoginStore = create<ILoginStore>((set) => ({
	login: async (loginRequest: LoginRequest) => {
		try {
			const response = await api.login(loginRequest);

			if (response.returnCode !== '0') throw new Error(response.message);

			const authState: AuthState = {
				account: response.account,
				token: response.token,
				role: response.role as SystemRoleEnum,
				timeZone: response.timeZone,
				isDefaultPassword: response.isDefaultPassword,
				loginDate: formatDateTime(new Date()),
				fpId: response.fpId,
			};
			useAuthStore.setState({ authState });

			return response.isDefaultPassword;
		} catch (e: any) {
			console.log(e);
			const res = e.response?.data;
			if (res['returnCode'] === 'SYS9999') throw new Error('Login failed: Wrong credentials');
			throw new Error(res['message'] || 'Something went wrong...');
		}
	},
	logout: async () => {
		useAuthStore.getState().clear();
		useOperatorListStore.getState().clear();
		useMasterOperatorListStore.getState().clear();
		useGameProviderListStore.getState().clear();
		useAccountSettingStore.getState().clear();
		useGameListStore.getState().clear();
		useCurrencyListStore.getState().clear();
	},
}));
