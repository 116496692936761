import { Paper } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { CheckboxSection } from '../components/CheckboxSection';
import PageTitle from '../components/common/PageTitle';
import { Option, optionAll } from '../components/common/SearchSelect';
import { DateSelectSection } from '../components/DateSelectSection';
import DownloadExcelButton from '../components/DownloadExcelButton';
import { GameSection } from '../components/GameSection';
import { OperatorSection } from '../components/OperatorSection';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import { TableHeader } from '../components/TableHeader';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { useFile } from '../hook/useFile';
import { DownloadGameWinLoseRequest, SearchWinLoseReportByGameRequest, WinLoseOperatorGameData } from '../models';
import { usePopupStore } from '../store/popupStore';
import { checkDateRange, formatDate } from '../utils/date';

type Props = {};

export const WinLoseGamePage: React.FC<Props> = () => {
	const { t } = useTranslation();
	const [data, setData] = useState<WinLoseOperatorGameData | null>(null);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [op, setOp] = useState<Option | null>(null);
	const [game, setGame] = useState<Option | null>(optionAll);
	const [includeTestAccount, setIncludeTestAccount] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const { handleFile } = useFile();
	const fileRequest = useRef<DownloadGameWinLoseRequest>();
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();

	const handleSearch = async () => {
		try {
			if (!startDate || !endDate || !game || !op) throw PopupEnum.InvalidInput;
			if (checkDateRange(startDate, endDate, 31)) throw PopupEnum.OverSearchDay;
			openPopup(PopupEnum.Loading);
			const req: SearchWinLoseReportByGameRequest = {
				startDate: formatDate(startDate),
				endDate: formatDate(endDate),
				gameCode: game.id,
				fpId: op.id,
				includeTestAccount,
			};
			const res = await api.searchWinLoseReportByGame(req);

			if (!res.data.gameCurrencyList.length) throw PopupEnum.ResultNotFound;
			fileRequest.current = req;
			setData(res.data);
			closePopup();
		} catch (error: any | PopupEnum) {
			setData(null);
			handleError(error);
		}
	};

	const downloadGameWinLose = async () => {
		try {
			setLoading(true);
			if (!fileRequest.current) throw PopupEnum.InvalidInput;
			const [excelFile, disposition] = await api.downloadGameWinLose(fileRequest.current);
			handleFile(excelFile, disposition);
		} catch (error) {
			handleError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="space-y-4">
			<PageTitle content={t('sidebar.report.winloseByCurrency')} />
			<SearchWrapper handleSearch={handleSearch} showCurrencyHint>
				<DateSelectSection
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				<GameSection game={game} setGame={setGame} hasAll />
				<OperatorSection op={op} setOp={setOp} />

				<CheckboxSection
					isTestAccount={includeTestAccount}
					toggleTestAccount={() => setIncludeTestAccount((value) => !value)}
				/>
			</SearchWrapper>
			{!!data?.gameCurrencyList.length && (
				<div className="space-y-12">
					<div className="flex justify-end p-2">
						<DownloadExcelButton download={downloadGameWinLose} isAll loading={loading} />
					</div>
					<Paper elevation={2} className="p-2 bg-primary-dark bg-opacity-5">
						<TableHeader info={data} />
					</Paper>
					{data.gameCurrencyList.map(({ winLoseList, ...row }, index) => (
						<Table
							key={index}
							list={winLoseList}
							components={{
								Toolbar: () => <TableHeader info={row} />,
							}}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default WinLoseGamePage;
