import { SearchTransactionReportResponse } from '../../models';

export const searchTransactionReportMock: SearchTransactionReportResponse = {
	data: [
		{
			currency: 'USD',
			validTurnover: 32,
			playerWinLose: -32,
			operatorWinLose: 32,
			betTime: '08/08/2022 07:26:32',
			refNo: 'Funky_va_309981290506_1-2_949963_82',
			gameCode: '602817',
			gameName: 'Kitchen God Welcome to New Year',
			turnover: 32,
			result: 'L',
			isTestAccount: true,
		},
		{
			currency: 'USD',
			validTurnover: 1,
			playerWinLose: 0.55,
			operatorWinLose: -0.55,
			betTime: '08/08/2022 07:24:33',
			refNo: 'Funky_fkg_200000022712',
			gameCode: '602817',
			gameName: 'Cash Or Crash',
			turnover: 1,
			result: 'W',
			isTestAccount: true,
		},
	],
	operatorName: 'Sylph',
	timeZone: '4',
	fpId: 1,
	playerId: 'FUN929018USD',
	currentPage: 1,
	pageCount: 1,
	pageSize: 2147483647,
	rowCount: 2,
	returnCode: '0',
	message: '',
};
