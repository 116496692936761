import { useTranslation } from 'react-i18next';
import DateSelect from './common/DateSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	startDate: Date | null;
	setStartDate: (date: Date | null) => void;
	endDate: Date | null;
	setEndDate: (date: Date | null) => void;
};

export function DateSelectSection({ startDate, setStartDate, endDate, setEndDate }: Props) {
	const { t } = useTranslation();

	return (
		<div className="space-y-3">
			<article className="flex flex-wrap gap-2 items-baseline">
				<SectionTitle content={`${t('Date')} ${t('search.Date_Limit')}`} />
			</article>
			<div className="flex gap-4 items-center">
				<DateSelect label={t('search.Date_Start')} date={startDate} setDate={setStartDate} />
				<DateSelect label={t('search.Date_End')} date={endDate} setDate={setEndDate} />
			</div>
		</div>
	);
}

export default DateSelectSection;
