import { yupResolver } from '@hookform/resolvers/yup';
import ReportIcon from '@mui/icons-material/Report';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, Paper, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import api from '../api';
import { ArrayTextInput } from '../components/common/ArrayTextInput';
import ConfirmActionPopup from '../components/common/ConfirmPopup';
import HintMsg from '../components/common/HintMsg';
import PageTitle from '../components/common/PageTitle';
import { PopupTableContent } from '../components/common/PopupTableContent';
import { Option } from '../components/common/SearchSelect';
import SectionTitle from '../components/common/SectionTitle';
import { ParentOperatorSection } from '../components/ParentOperatorSection';
import AccountTypeEnum from '../enum/accountTypeEnum';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { I18nStoreType } from '../i18n/react-i18next';
import { useAuthStore } from '../store/authStore';
import { useMasterOperatorListStore } from '../store/masterOperatorListStore';
import { usePopupStore } from '../store/popupStore';
import { IP_V4_MASK, IP_V6_MASK, OPERATOR_NAME_RULE } from '../utils/regexp';
import { getSubLineDetailMessage } from '../utils/subLine';

const validationSchema = (accountType: AccountTypeEnum) =>
	yup
		.object({
			operatorName: yup
				.string()
				.max(20, 'inputErrorMsg.Size_20')
				.matches(OPERATOR_NAME_RULE, 'inputErrorMsg.Valid_Combination_Type'),
			serverUrl:
				accountType === AccountTypeEnum.Tr ? yup.string().url() : yup.string().url().required('inputErrorMsg.Required'),
		})
		.required();

type FormData = {
	operatorName: string;
	serverUrl: string;
};

type Props = {};

export const AddSubLinePage = (props: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { authState } = useAuthStore();
	const { opApiWhiteList } = useMasterOperatorListStore();
	const [parentOp, setParentOp] = useState<Option | null>(null);
	const [parentOpError, setParentOpError] = useState<string>('');
	const [apiWhiteList, setApiWhiteList] = useState<string[]>([]);
	const [apiWhiteListError, setApiWhiteListError] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);
	const { openPopup, setPopup } = usePopupStore();
	const { handleError } = useError();
	const {
		register,
		getValues,
		handleSubmit,
		reset,
		formState: { isSubmitted, errors },
	} = useForm<FormData>({
		resolver: yupResolver(validationSchema(AccountTypeEnum.Tr)),
	});

	const goBack = () => navigate(-1);

	const checkEmptyError = useCallback(() => {
		setParentOpError(parentOp ? '' : 'inputErrorMsg.Required_Selected');
	}, [parentOp]);

	useEffect(() => {
		if (isSubmitted) {
			setApiWhiteListError('');
			checkEmptyError();
		}
	}, [isSubmitted, checkEmptyError]);

	const createSubLineRequisition = async () => {
		try {
			openPopup(PopupEnum.Loading);

			const { requisitionId } = await api.createSubLineRequisition({
				operatorName: getValues('operatorName'),
				serverUrl: getValues('serverUrl'),
				apiWhiteList: apiWhiteList,
				parentOperatorId: Number(parentOp?.id),
			});
			setPopup(PopupEnum.CustomMessage, `${t('management.SubLineRequisition.RequisitionId')}: ${requisitionId}`);
			resetForm();
		} catch (error: any | PopupEnum) {
			handleError(error);
		}
	};

	const resetForm = () => {
		reset();
		setParentOp(null);
		setApiWhiteList([]);
	};

	const validateIp = (ip: string) => {
		if (!(ip.match(IP_V6_MASK) || ip.match(IP_V4_MASK))) {
			setApiWhiteListError('inputErrorMsg.Valid_Ip');
			return false;
		}
		if (apiWhiteList.some((item) => item === ip)) {
			setApiWhiteListError('inputErrorMsg.Repeat_Ip');
			return false;
		}
		setApiWhiteListError('');
		return true;
	};

	const getResultMessage = () =>
		getSubLineDetailMessage(t, {
			operatorName: getValues('operatorName'),
			parentOp: parentOp?.label || authState?.fpId || '',
			serverUrl: getValues('serverUrl'),
			apiWhiteList: apiWhiteList.join(' , '),
		});

	const openComfirmPopup = () => {
		if (!parentOp) return;
		setOpen(true);
	};

	return (
		<div className="space-y-4 lg:w-[700px] w-auto mx-auto">
			<ConfirmActionPopup open={open} setOpen={setOpen} comfirmAction={createSubLineRequisition}>
				<PopupTableContent messageLines={getResultMessage()} />
			</ConfirmActionPopup>
			<PageTitle content={t('management.AddNewSubLine.Title')} />
			<Paper
				className="grid grid-cols-12 gap-4 mt-4 p-8 bg-gray-50"
				component="form"
				onSubmit={handleSubmit(openComfirmPopup)}
			>
				<div className="space-y-3 col-span-6">
					<SectionTitle content={t('management.SubLineRequisition.OperatorName')} required />
					<TextField
						className="w-full"
						variant="outlined"
						autoComplete="off"
						error={!!errors['operatorName']}
						helperText={
							errors['operatorName'] ? (t(errors['operatorName'].message as keyof I18nStoreType) as string) : ''
						}
						{...register('operatorName')}
					/>
					<HintMsg content={t('inputHintMsg.Operator_Name_Limit')} />
				</div>
				<ParentOperatorSection
					widthStyle="w-full col-span-12 md:col-span-6"
					op={parentOp}
					setOp={setParentOp}
					errorText={parentOpError ? (t(parentOpError as keyof I18nStoreType) as string) : ''}
					required
				/>

				<div className="space-y-3 w-full col-span-12">
					<SectionTitle content={t('management.SubLineRequisition.ServerUrl')} />
					<TextField
						className="w-full"
						variant="outlined"
						autoComplete="off"
						{...register('serverUrl')}
						error={!!errors['serverUrl']}
						helperText={errors['serverUrl'] ? (t(errors['serverUrl'].message as keyof I18nStoreType) as string) : ''}
					/>
					<HintMsg content={t('inputHintMsg.Server_URL_SML_Needed')} />
					<HintMsg content={t('inputHintMsg.Server_URL_Format')} />
				</div>
				<ArrayTextInput
					title={t('management.SubLineRequisition.ApiWhiteList')}
					list={apiWhiteList}
					validate={validateIp}
					setList={setApiWhiteList}
					emptyText={t('inputHintMsg.White_List_Empty')}
					errorText={apiWhiteListError ? (t(apiWhiteListError as keyof I18nStoreType) as string) : ''}
				/>

				<div className="col-span-12 flex items-center flex-wrap gap-1">
					<HintMsg content={t('inputHintMsg.Master_White_List')} />
					{parentOp?.id && opApiWhiteList[parentOp?.id].map((ip) => <Chip key={ip} label={ip} />)}
				</div>
				<div className="col-span-12 flex items-center flex-wrap gap-1">
					<ReportIcon />
					<HintMsg content={t('inputHintMsg.Notify_For_K_Currency')} />
				</div>

				<div className="pt-8 col-span-12 flex justify-center flex-wrap gap-4">
					<Button variant="redGradient" className="color-[#ae0000]" onClick={goBack}>
						{t('management.AddNewSubLine.CancelButton')}
					</Button>
					<LoadingButton variant="gradient" type="submit">
						{t('management.MemberCreate.SubmitButton')}
					</LoadingButton>
				</div>
			</Paper>
		</div>
	);
};

export default AddSubLinePage;
