export const getGameProviderListMock = {
	list: [
		{
			userAgent: '1',
			gameProviderName: 'gp1',
		},
		{
			userAgent: '17',
			gameProviderName: 'gp2',
		},
	],
};

export default getGameProviderListMock;
