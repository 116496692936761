import { usePage } from './usePage';

type Props = {
	currentPage: number;
	pageSize: number;
	pageCount: number;
	initPaged: (list: any[]) => void;
	getPaged: (list: any[], page: number) => void;
};

export function useCustomPage(size: number = 100, setList: (list: any[]) => void): Props {
	const { currentPage, pageCount, handlePage } = usePage(size);

	const initPaged = (list: any[]) => {
		handlePage(1, Math.ceil(list.length / size));
		setList(list.slice(0, size));
	};

	const getPaged = (list: any[], page: number) => {
		if (page === currentPage) return;
		handlePage(page);
		setList(list.slice((page - 1) * size, page * size));
	};

	return {
		currentPage,
		pageSize: size,
		pageCount,
		initPaged,
		getPaged,
	};
}
