import { Paper } from '@mui/material';
import { DataGrid, GridColDef, GridSlotsComponent } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
	WinLoseDataRow,
	WinLosePlayerDataRow,
	BetData,
	TxTransactionHistoryData,
	TransactionDataRow,
	RequisitionDataRow,
	Account,
} from '../models';
import { winloseColumns } from './ReportTableColumns';

type Props = {
	list:
		| WinLoseDataRow[]
		| WinLosePlayerDataRow[]
		| BetData[]
		| TxTransactionHistoryData[]
		| TransactionDataRow[]
		| RequisitionDataRow[]
		| Account[];
	components?: Partial<GridSlotsComponent>;
	columns?: ((t: TFunction, component?: (value: string) => ReactNode) => GridColDef)[];
	initialState?: {};
};

export function Table({ list, components, columns = winloseColumns, initialState = {} }: Props) {
	const { t } = useTranslation();

	return (
		<Paper className="p-2">
			<DataGrid
				rows={list.map((row, index) => ({ id: index, ...row }))}
				columns={columns.map((col) => col(t))}
				autoHeight
				hideFooter
				disableSelectionOnClick
				disableColumnMenu
				components={components}
				className="border-none"
				initialState={initialState}
			/>
		</Paper>
	);
}
