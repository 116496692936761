import { createTheme } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		gradient: true;
		redGradient: true;
		blueGradient: true;
		whiteOutlined: true;
	}
}
declare module '@mui/material/Chip' {
	interface ChipPropsVariantOverrides {
		alert: true;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		description: true;
		pageTitle: true;
	}
}

export const theme = createTheme({
	palette: {
		primary: {
			main: '#005aac',
			dark: '#0c3e6d',
		},
		secondary: { main: '#0096fd' },
		error: { main: '#c01717' },
	},
	typography: {
		fontFamily:
			'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'gradient' },
					style: {
						fontWeight: '600',
						letterSpacing: '2px',
						width: '200px',
						color: 'white',
						background: `linear-gradient(263deg, #00bef5 0%, #0096fd 100%)`,
						borderRadius: '24px',
						':hover': {
							boxShadow: '#6bd2ff66 0 13px 22px',
						},
					},
				},
				{
					props: { variant: 'redGradient' },
					style: {
						fontWeight: '600',
						letterSpacing: '2px',
						width: '200px',
						color: 'white',
						background: `linear-gradient(263deg, #ae0000 0%, #bb2f0cce 100%)`,
						borderRadius: '24px',
						':hover': {
							boxShadow: '#ff9f6b66 0 13px 22px',
						},
					},
				},
				{
					props: { variant: 'blueGradient' },
					style: {
						fontWeight: '500',
						letterSpacing: '1px',
						width: '200px',
						color: 'white',
						background: `linear-gradient(263deg, #0093f5 0%, #005AAC 100%)`,
						':hover': {
							boxShadow: '#6bd2ff66 0 13px 22px',
						},
					},
				},
				{
					props: { variant: 'whiteOutlined', size: 'small' },
					style: {
						fontWeight: '300',
						fontSize: '0.9rem',
						color: '#ffffffea',
						border: '2px solid #ffffffd4',
						borderRadius: '4px',
						padding: '0.15rem 0.6rem',
						':hover': {
							background: '#ffffff2d',
						},
					},
				},
			],
		},
		MuiTextField: {
			defaultProps: {
				size: 'small',
				variant: 'outlined',
			},
		},
		MuiSelect: {
			defaultProps: {
				size: 'small',
				variant: 'outlined',
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				columnHeader: {
					background: '#00000005',
					':focus': {
						outline: 'none',
					},
				},
				toolbarContainer: {},
				cell: {
					':focus': {
						outline: 'none',
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {},
		},
		MuiChip: {
			variants: [
				{
					props: { variant: 'alert' },
					style: {
						background: '#0000000a',
						color: '#c01717',
						fontWeight: '500',
					},
				},
			],
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.color === 'default' && { background: '#0000000a', color: '#0000009a' }),
					...(ownerState.variant === 'outlined' && { borderWidth: '2px' }),
					fontWeight: '700',
					borderRadius: '4px',
				}),
				label: {
					padding: '0 8px',
				},
			},
		},
		MuiTypography: {
			variants: [
				{
					props: { variant: 'pageTitle' },
					style: {
						color: '#0c3e6d',
						fontWeight: 900,
						fontSize: '1.125rem',
						lineHeight: '2rem',
					},
				},
				{
					props: { variant: 'subtitle1' },
					style: {
						fontWeight: 600,
						fontSize: '1rem',
						lineHeight: '1.5rem',
					},
				},
				{
					props: { variant: 'subtitle2' },
					style: {
						color: '#0000009a',
						fontWeight: 500,
						fontSize: '0.875rem',
						lineHeight: '1.25rem',
					},
				},
			],
		},
	},
});

export default theme;
