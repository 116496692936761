import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SystemRoleEnum from '../../enum/systemRoleEnum';

type SubButton = {
	label: string;
	path: string;
	newTab?: boolean;
	allowedRoles?: SystemRoleEnum[];
	allowedFpIds?: string[];
};

type Props = {
	icon: React.ReactNode;
	text: string;
	subList: SubButton[];
	defaultOpen?: boolean;
	allowedRoles?: SystemRoleEnum[];
	allowedFpIds?: string[];
};

const CollapseButton: React.FC<Props> = ({ icon, text, subList, defaultOpen = false, allowedFpIds, allowedRoles }) => {
	const { isIncludeRole, isIncludeFpId } = useAuthStore();
	const [open, setOpen] = useState(defaultOpen);
	const handleClick = () => setOpen(!open);
	const go = useNavigate();
	const location = useLocation();
	if (!isIncludeRole(allowedRoles) || !isIncludeFpId(allowedFpIds)) return null;
	return (
		<>
			<ListItemButton onClick={handleClick}>
				<ListItemIcon className="text-white">{icon}</ListItemIcon>
				<ListItemText primary={text} primaryTypographyProps={{ className: 'font-semibold text-lg' }} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{subList.map(({ label, path, newTab, allowedRoles, allowedFpIds }) => {
						if (!isIncludeRole(allowedRoles) || !isIncludeFpId(allowedFpIds)) return null;
						return (
							<ListItem
								key={label}
								disablePadding
								className="mb-1 rounded-xl overflow-hidden"
								secondaryAction={
									newTab && (
										<IconButton edge="end" onClick={() => window.open(path)}>
											<OpenInNewIcon />
										</IconButton>
									)
								}
							>
								<ListItemButton className={location.pathname === path ? 'bg-[#00000024]' : ''} onClick={() => go(path)}>
									<ListItemText primary={label} primaryTypographyProps={{ className: 'text-sm' }} />
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</Collapse>
		</>
	);
};

export default CollapseButton;
