import { TFunction, useTranslation } from 'react-i18next';
import StatusEnum from '../enum/statusEnum';
import { I18nStoreType } from '../i18n/react-i18next';
import SearchSelect, { Option } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

const getStatusList = (t: TFunction): Option[] =>
	Object.keys(StatusEnum).map((status) => getStatusOption(t, status as StatusEnum));

export const getStatusOption = (t: TFunction, status: StatusEnum): Option => ({
	id: status,
	label: t(`management.MemberForm.${status}` as keyof I18nStoreType) as string,
});

type Props = {
	status: Option | null;
	setStatus: (option: Option | null) => void;
	required?: boolean;
	errorText?: string;
	widthStyle?: string;
};

export const StatusSection = ({ status, setStatus, required, errorText, widthStyle }: Props) => {
	const { t } = useTranslation();

	return (
		<div className={`space-y-3 ${widthStyle || 'w-[200px]'}`}>
			<SectionTitle content={t('management.MemberQuery.Status')} required={required} />
			<SearchSelect
				widthStyle="w-full"
				label={t('management.MemberQuery.SelectStatus')}
				option={status}
				setOption={setStatus}
				options={getStatusList(t)}
				errorText={errorText}
			/>
		</div>
	);
};

export default StatusSection;
