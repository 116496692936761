import type { RouteObject } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import SystemRoleEnum from './enum/systemRoleEnum';
import Layout from './Layout';
import {
	NewsPage,
	LoginPage,
	WinLosePage,
	WinLoseGamePage,
	WinLosePlayerPage,
	WinLoseGpPage,
	TransactionReportPage,
	TransferWalletBettingListPage,
	TransferWalletTransactionHistoryPage,
	ChangePasswordPage,
	MemberPage,
	MemberCreatePage,
	MemberUpdatePage,
	AddSubLinePage,
	SubLineRequisitionsPage,
	BetQueryPage,
} from './pages';
import { gpWinLoseAllowList } from './utils/allowList';

const routes: RouteObject[] = [
	{
		path: '/',
		element: <RequireAuth />,
		children: [
			{
				path: '/',
				element: <Layout />,
				children: [
					{
						path: '/',
						element: <NewsPage />,
					},
					{
						path: 'winlose',
						element: <WinLosePage />,
					},
					{
						element: <RequireAuth allowedRoles={[SystemRoleEnum.Admin]} allowedFpIds={gpWinLoseAllowList} />,
						children: [
							{
								path: 'winloseByGameProvider',
								element: <WinLoseGpPage />,
							},
						],
					},
					{
						path: 'winLoseGame',
						element: <WinLoseGamePage />,
					},
					{
						path: 'winLosePlayer',
						element: <WinLosePlayerPage />,
					},

					{
						path: 'transactionReport',
						element: <TransactionReportPage />,
					},
					{
						path: 'singleBetQuery',
						element: <BetQueryPage />,
					},
					{
						element: (
							<RequireAuth
								allowedRoles={[
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.TrManager,
									SystemRoleEnum.TrMaster,
								]}
								allowedFpIds={gpWinLoseAllowList}
							/>
						),
						children: [
							{
								path: 'trBettingList',
								element: <TransferWalletBettingListPage />,
							},
							{
								path: 'trTransactionHistory',
								element: <TransferWalletTransactionHistoryPage />,
							},
						],
					},
					{
						path: 'changePassword',
						element: <ChangePasswordPage />,
					},

					{
						element: (
							<RequireAuth
								allowedRoles={[
									SystemRoleEnum.Admin,
									SystemRoleEnum.Reseller,
									SystemRoleEnum.SmlMaster,
									SystemRoleEnum.TrMaster,
								]}
							/>
						),
						children: [
							{
								path: 'member',
								element: <MemberPage />,
							},
							{
								path: 'member/create',
								element: <MemberCreatePage />,
							},
							{
								path: 'member/update',
								element: <MemberUpdatePage />,
							},
							{
								path: 'addNewSubLine',
								element: <AddSubLinePage />,
							},
							{
								path: 'subLineRequisition',
								element: <SubLineRequisitionsPage />,
							},
						],
					},
				],
			},
		],
	},
	{
		path: '/login',
		element: <LoginPage />,
	},
	{
		path: '/*',
		element: <h2>404 Not Found Page</h2>,
	},
];

export default routes;
