import { Chip } from '@mui/material';

type Props = {
	label?: string;
	value?: string;
	primary?: boolean;
	color?: 'primary' | 'default' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
};

export function CustomChip({ color, label, value }: Props) {
	const createLabel = (label: string, value: string) => `${label} ${value}`;

	return value ? <Chip color={color} label={label ? createLabel(label, value) : value} /> : <></>;
}
