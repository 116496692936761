import { FilterOptionsState } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export type Option<T = string> = {
	id: T;
	label: T;
};

export const optionAll: Option = {
	id: 'All',
	label: 'All',
};

export type FilterOptions = (options: Option[], state: FilterOptionsState<Option>) => Option[];

type Props = {
	widthStyle?: string;
	options: Option[];
	option: Option | null;
	setOption: (option: Option<any> | null) => void;
	label?: string;
	hasAll?: boolean;
	errorText?: string;
	disabled?: boolean;
	filterOptions?: FilterOptions;
};

const defaultFilterOptions: FilterOptions = (options, { inputValue }) => {
	const checkInclude = (source: string) => source.toUpperCase().includes(inputValue.toUpperCase());
	return options.filter((option) => checkInclude(option.id) || checkInclude(option.label));
};

export const SearchSelect = ({
	widthStyle,
	options,
	option,
	setOption,
	label,
	hasAll = false,
	errorText,
	disabled = false,
	filterOptions = defaultFilterOptions,
}: Props) => {
	return (
		<Autocomplete
			value={option}
			onChange={(e, newValue) => setOption(newValue)}
			className={widthStyle || 'w-[200px]'}
			options={hasAll ? [optionAll, ...options] : options}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			filterOptions={filterOptions}
			disabled={disabled}
			renderInput={(params) => <TextField {...params} label={label} error={!!errorText} helperText={errorText} />}
			sx={{ ':disabled': { color: 'black' } }}
		/>
	);
};

export default SearchSelect;
