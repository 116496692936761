import { QueryAccountResponse } from '../../models';

export const queryAccountMock: QueryAccountResponse = {
	accountList: [
		{
			accountId: 'AGNES',
			accountName: 'agnes_chou',
			agentName: 'Sylph',
			presentationRole: 'Admin',
			status: 'Active',
			email: '1',
			contactPhone: '2',
			mobilePhone: '3',
			remark: '4',
			lastLoginTime: '2022-08-26T10:57:45.0449008Z',
			accountType: 'All',
			role: 'Admin',
		},
		{
			accountId: 'ENZO',
			accountName: 'enzo',
			agentName: 'Sylph',
			presentationRole: 'Admin',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-26T11:09:05.1429458Z',
			accountType: 'All',
			role: 'Admin',
		},
		{
			accountId: 'SYLPH',
			accountName: 'sylph',
			agentName: 'Sylph',
			presentationRole: 'Master',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-24T08:01:24.8068678Z',
			accountType: 'All',
			role: 'Master',
		},
		{
			accountId: 'SYLPHS',
			accountName: 'sylphs',
			agentName: 'Sylph',
			presentationRole: 'SmlMaster',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-07-20T04:47:20.0544127Z',
			accountType: 'Sml',
			role: 'Master',
		},
		{
			accountId: 'TESTMASTER01',
			accountName: 'testMaster01Name',
			agentName: 'Sylph',
			presentationRole: 'SmlMaster',
			status: 'Active',
			email: 'master01@cc.com',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-22T03:15:42.5640364Z',
			accountType: 'Sml',
			role: 'Master',
		},
		{
			accountId: 'TESTRESELLER',
			accountName: 'testResellerName',
			agentName: 'Sylph',
			presentationRole: 'Reseller',
			status: 'Active',
			email: 'reseller.com',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-07-15T11:21:53.8783322Z',
			accountType: 'All',
			role: 'Reseller',
		},
		{
			accountId: 'TESTSMLMANAGER',
			accountName: 'TestSmlManager',
			agentName: 'Sylph',
			presentationRole: 'SmlManager',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-04T02:43:47.4141417Z',
			accountType: 'Sml',
			role: 'Manager',
		},
		{
			accountId: 'TESTSMLMASTER',
			accountName: 'TestSmlMaster',
			agentName: 'Sylph',
			presentationRole: 'SmlMaster',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-04T02:42:05.5063157Z',
			accountType: 'Sml',
			role: 'Master',
		},
		{
			accountId: 'TESTTRMANAGER',
			accountName: 'TestTrManager',
			agentName: 'Sylph',
			presentationRole: 'TrManager',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-04T02:44:15.9054593Z',
			accountType: 'Tr',
			role: 'Manager',
		},
		{
			accountId: 'TESTTRMASTER',
			accountName: 'TestTrMaster',
			agentName: 'Sylph',
			presentationRole: 'TrMaster',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-04T02:43:58.5974027Z',
			accountType: 'Tr',
			role: 'Master',
		},
		{
			accountId: 'ZOE',
			accountName: 'zoe',
			agentName: 'Sylph',
			presentationRole: 'Admin',
			status: 'Active',
			email: '',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-25T09:27:39.9571892Z',
			accountType: 'All',
			role: 'Admin',
		},
		{
			accountId: 'ZOETEST',
			accountName: 'zoetest',
			agentName: 'Sylph',
			presentationRole: 'Admin',
			status: 'Active',
			email: 'string',
			contactPhone: '',
			mobilePhone: '',
			remark: '',
			lastLoginTime: '2022-08-25T09:28:04.3309167Z',
			accountType: 'All',
			role: 'Admin',
		},
	],
};

export default queryAccountMock;
