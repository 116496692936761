import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrencyListStore } from '../store/currencyListStore';
import { usePopupStore } from '../store/popupStore';
import SearchSelect, { Option } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	currency: Option | null;
	setCurrency: (option: Option | null) => void;
	hasAll?: boolean;
};

export const CurrencySection: React.FC<Props> = ({ currency, setCurrency, hasAll }) => {
	const { t } = useTranslation();
	const {initCurrencyList,transferToOptions}=useCurrencyListStore();
	const { openPopup } = usePopupStore();
	
	useEffect(() => {
		initCurrencyList(openPopup);
	}, [initCurrencyList, openPopup]);

	return (
		<div className="space-y-3">
			<SectionTitle content={t('Currency')} />
			<SearchSelect
				label={t('search.Select_Currency')}
				option={currency}
				setOption={setCurrency}
				options={transferToOptions()}
				hasAll={hasAll}
			/>
		</div>
	);
};

export default CurrencySection;
