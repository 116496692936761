import { useTranslation } from 'react-i18next';
import SearchSelect, { Option } from './common/SearchSelect';
import SectionTitle from './common/SectionTitle';

type Props = {
	widthStyle?: string;
	option: Option | null;
	setOption: (option: Option<any> | null) => void;
	options: Option[];
	required?: boolean;
	errorText?: string;
	disabled?: boolean;
};

export const AccountTypeSection = ({
	widthStyle,
	option,
	setOption,
	options,
	required,
	errorText,
	disabled = false,
}: Props) => {
	const { t } = useTranslation();

	return (
		<div className={`space-y-3 ${widthStyle || 'w-[200px]'}`}>
			<SectionTitle content={t('management.MemberForm.AccountType')} required={required} />
			<SearchSelect
				widthStyle="w-full"
				label={t('management.MemberForm.Select')}
				option={option}
				setOption={setOption}
				options={options}
				errorText={errorText}
				disabled={disabled}
			/>
		</div>
	);
};

export default AccountTypeSection;
