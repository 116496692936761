import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import api from '../api';
import { InputSection } from '../components/common/InputSection';
import PageTitle from '../components/common/PageTitle';
import { betColumns } from '../components/ReportTableColumns';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { BetData } from '../models';
import { usePopupStore } from '../store/popupStore';

export const BetQueryPage = () => {
	const { t } = useTranslation();
	const [data, setData] = useState<BetData | null>(null);
	const [refNo, setRefNo] = useState<string>('');
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();

	async function handleSearch() {
		try {
			if (refNo === '') throw PopupEnum.InvalidInput;
			openPopup(PopupEnum.Loading);

			const res = await api.getBetByRefNo({
				refNo,
			});
			if (!res.data) throw PopupEnum.ResultNotFound;
			setData(res.data);
			closePopup();
		} catch (error: any | PopupEnum) {
			handleError(error);
			setData(null);
		}
	}

	return (
		<div className="space-y-4">
			<PageTitle content={t('sidebar.report.singleBetQuery')} />
			<SearchWrapper handleSearch={() => handleSearch()} showCurrencyHint>
				<InputSection
					title={t('reportTable.RefNo')}
					label={t('search.Input_RefNo')}
					value={refNo}
					setValue={setRefNo}
					widthStyle="w-[300px]"
				/>
			</SearchWrapper>
			{data && (
				<div className="space-y-12">
					<Table list={[data]} columns={betColumns} />
				</div>
			)}
		</div>
	);
};

export default BetQueryPage;
