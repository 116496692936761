import { TextField } from '@mui/material';
import SectionTitle from './SectionTitle';

type Props = {
	widthStyle?: string;
	title: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	value: any;
	setValue?: (value: string) => void;
	errorText?: string;
	error?: any;
	register?: any;
};

export const InputSection = (props: Props) => {
	const { widthStyle, title, label, required, disabled, value, setValue, errorText, register } = props;
	return (
		<div className={`space-y-3 ${widthStyle || 'w-[200px]'}`}>
			<SectionTitle content={title} required={required} />
			<TextField
				className="w-full"
				label={label}
				variant="outlined"
				autoComplete="off"
				disabled={disabled}
				value={value}
				onChange={(e) => setValue && setValue(e.target.value.trim())}
				error={!!errorText}
				helperText={errorText}
				{...register}
			/>
		</div>
	);
};

export default InputSection;
