export const getOperatorListMock = {
	operatorList: [
		{
			fpId: '0',
			name: 'Demosite',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '1',
			name: 'Sylph',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '2',
			name: 'GamingWorld',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '3',
			name: 'DrGaming',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '5',
			name: 'SBOBET',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '6',
			name: 'Digient',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '7',
			name: 'BetAdvance',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '8',
			name: 'Superswan',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '9',
			name: '568Win',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '11',
			name: 'IBC',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '12',
			name: 'SylphT',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '13',
			name: 'DragonSoft',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '14',
			name: 'SylphIBC',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '15',
			name: 'ABLIVECASINO',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '16',
			name: 'Vin88',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '17',
			name: 'Xitment',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '18',
			name: 'EasyGaming',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '19',
			name: 'EasyGame88',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '21',
			name: 'WM-W21test',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '22',
			name: 'AUQI-hbo',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '23',
			name: 'Ambsuperslot',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '24',
			name: 'PGS',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '25',
			name: 'Fortuno',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '26',
			name: 'MGP',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '27',
			name: 'Hwin168-DSTBET',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '28',
			name: 'Hwin168',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '30',
			name: 'xtu168',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '31',
			name: 'Great Ent Co',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '32',
			name: 'EXP',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '33',
			name: 'GamingSoft-T',
			isMasterOperator: true,
			isReseller: true,
			isTransfer: true,
		},
		{
			fpId: '34',
			name: 'GamingSoft-S',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '35',
			name: 'HWFNKWENFA',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '36',
			name: 'GPlay',
			isMasterOperator: true,
			isReseller: true,
			isTransfer: false,
		},
		{
			fpId: '37',
			name: 'ThirtyThree',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '38',
			name: 'Immortal',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '39',
			name: 'istar',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '41',
			name: 'Mrslotty-ire',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '42',
			name: 'Spadeclub',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '43',
			name: 'ism',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '44',
			name: 'Big Data Centre SDN',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '45',
			name: 'tg',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '46',
			name: 'Egadmin',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '47',
			name: 'Razer',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '48',
			name: 'Markor',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '49',
			name: 'HWFNKWF',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '50',
			name: 'X2',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '51',
			name: 'Ironman',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '52',
			name: 'Winbet',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '53',
			name: 'XiaoXiang',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '54',
			name: 'JXIT',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '55',
			name: 'USSBET',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '56',
			name: 'Neftsoft',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '57',
			name: 'Hwin168-rapa',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '58',
			name: 'Hwin168-HWUP8FK',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '59',
			name: 'Leekie',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '60',
			name: 'Betksh',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '61',
			name: 'WS',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '62',
			name: 'Hwin168-Gbet',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '63',
			name: 'GST',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '64',
			name: 'HWTGBTFNK',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '65',
			name: 'Okbet',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '99',
			name: 'maple',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '111',
			name: 'SylphReseller',
			isMasterOperator: false,
			isReseller: true,
			isTransfer: true,
		},
		{
			fpId: '128',
			name: 'Hwin168-all',
			isMasterOperator: false,
			isReseller: true,
			isTransfer: true,
		},
		{
			fpId: '168',
			name: 'JClub777',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '1680',
			name: 'demogame',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '9999',
			name: 'kevin',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '10004',
			name: 'zoe',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '10008',
			name: 'GARYSUBLINE01',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '10009',
			name: 'GARYSUBLINE03',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '10010',
			name: 'GARYTESTSUBLINE03',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '30001',
			name: 'DrGaming-DG001',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '30002',
			name: 'DrGaming-DG002',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '30003',
			name: 'DrGaming-DG003',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '30005',
			name: 'DrGaming-DG005',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '30006',
			name: 'DrGaming-DG006',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '30007',
			name: 'DrGaming-DG007',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '80001',
			name: 'Superswan-SS001',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '80005',
			name: 'Superswan-SS005',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '80006',
			name: 'Superswan-SS006',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '80007',
			name: 'Superswan-SS007',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '80009',
			name: 'Superswan-SS009',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '90001',
			name: '568winCT',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '130001',
			name: 'GARYSUBLINE02',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '150001',
			name: 'ABLIVECASINO-wbfapi',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150002',
			name: 'ABLIVECASINO-lgf',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150003',
			name: 'ABLIVECASINO-vhf',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150004',
			name: 'ABLIVECASINO-j19',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150006',
			name: 'ABLIVECASINO-yaf',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150007',
			name: 'ABLIVECASINO-hbf',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150008',
			name: 'ABLIVECASINO-lkf',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150009',
			name: 'ABLIVECASINO-j31HKD',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '150010',
			name: 'ABLIVECASINO-j31KVND',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180001',
			name: 'EasyGaming-KK001',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180002',
			name: 'EasyGaming-85bet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180003',
			name: 'EasyGaming-VW',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180004',
			name: 'EasyGaming-BKK',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180005',
			name: 'EasyGaming-SKA888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180006',
			name: 'EasyGaming-V79',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '180007',
			name: 'EasyGaming-Play599',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '190001',
			name: 'EasyGame88-VND',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '190002',
			name: 'EasyGame88-THB',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '190003',
			name: 'EasyGame88-IDR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '190004',
			name: 'EasyGame88-MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210001',
			name: 'WM-VBF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210002',
			name: 'WM-GOF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210003',
			name: 'WM-AAF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210004',
			name: 'WM-WCF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210005',
			name: 'WM-INF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210006',
			name: 'WM-BOF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210007',
			name: 'WM-JQF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210008',
			name: 'WM-CWF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210009',
			name: 'WM-WWF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '210010',
			name: 'WM-JZF',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '230001',
			name: 'Amb-Supergame99',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '260001',
			name: 'MGP-RSG',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '270001',
			name: 'Dstbet-bet1776',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270002',
			name: 'Dstbet-hoh966',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270003',
			name: 'Dstbet-ubet3399',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270004',
			name: 'Dstbet-wynnbet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270005',
			name: 'Dstbet-ozbetau',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270006',
			name: 'Dstbet-jutaclub',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270007',
			name: 'Dstbet-luckybet333',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270008',
			name: 'Dstbet-wcgbet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270009',
			name: 'Dstbet-play22bet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270010',
			name: 'Dstbet-win777',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270011',
			name: 'Dstbet-winners33',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270012',
			name: 'GaryHwinDSTBET',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '270013',
			name: 'GaryTest02',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280001',
			name: 'Hwin168-Bagus545',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280002',
			name: 'Hwin168-Menang44',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280003',
			name: 'Hwin168-jom8888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280004',
			name: 'Hwin168-bossku96',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280005',
			name: 'Hwin168-starplay888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280006',
			name: 'Hwin168-9club33',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280007',
			name: 'Hwin168-7liveasean88',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280008',
			name: 'Hwin168-googleplay',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280009',
			name: 'Hwin168-3aw8',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '280010',
			name: 'Hwin168-918kisscompany',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '300001',
			name: 'xtu168-yibo-MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '300002',
			name: 'xtu168-MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '300003',
			name: 'xtu168-IDR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '300004',
			name: 'xtu168-VND',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '300005',
			name: 'xtu168-CNY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '320001',
			name: 'EXP-ambsuperapi',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '320002',
			name: 'EXP-ambnewsportbook',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '330001',
			name: 'GamingSoft-T-whitejoker88',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330002',
			name: 'GamingSoft-T-whitejokercn',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330003',
			name: 'GamingSoft-T-fab789',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330004',
			name: 'GamingSoft-T-whitejokerin',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330005',
			name: 'GamingSoft-T-ttbet18my',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330006',
			name: 'GamingSoft-T-wynnbet365sg',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330007',
			name: 'GamingSoft-T-BETASIAMY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330008',
			name: 'GamingSoft-T-CASINOJRMY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330009',
			name: 'GamingSoft-T-DELUXEWINMY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330010',
			name: 'GamingSoft-T-TLGBETHK',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330201',
			name: 'GamingSoft-T-MMK22-jdhbetid',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330301',
			name: 'GamingSoft-T-Daniel Tan-VVIP68',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330302',
			name: 'GamingSoft-T-DT-BINGOCITY88PHP',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330401',
			name: 'GamingSoft-T-WTB-IG918',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330402',
			name: 'GamingSoft-T-WTB-IKFC4U',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330403',
			name: 'GamingSoft-T-WTB-JOMWIN8',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330404',
			name: 'GamingSoft-T-WTB-EGOFFICE4U',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330405',
			name: 'GamingSoft-T-WTB-DKING88',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330406',
			name: 'GamingSoft-T-WTB-TRUST88',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330407',
			name: 'GamingSoft-T-WTB-1SLOT',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330408',
			name: 'GamingSoft-T-WTB-JUDIASIA96',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330409',
			name: 'GamingSoft-T-WTB-WINSCLUB888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330410',
			name: 'GamingSoft-T-WTB-JW88MY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330501',
			name: 'GamingSoft-T-CSL-CSLTHB',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330502',
			name: 'GamingSoft-T-CSL-CSLVND',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330601',
			name: 'GamingSoft-T-ABT-BURBERRY777MY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330602',
			name: 'GamingSoft-T-ABT-EZY18DEMOMYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330603',
			name: 'GamingSoft-T-ABT-EZY18DEMOUSD',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330604',
			name: 'GamingSoft-T-ABT-M1PLAYMYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330701',
			name: 'GamingSoft-T-DM-BIGBETWOLFAUD',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330801',
			name: 'GamingSoft-T-GM-BET123ID',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330802',
			name: 'GamingSoft-T-GM-BET123TH',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330803',
			name: 'GamingSoft-T-GM-BET123VN',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330901',
			name: 'GamingSoft-T-1XPOWER-1XGCSMYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330902',
			name: 'GamingSoft-T-1XPOWER-EZY18MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330903',
			name: 'GamingSoft-T-1XPOWER-EZY18USD',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '330904',
			name: 'GamingSoft-T-1XPOWER-WLT54MY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331000',
			name: 'GamingSoft-T-BB77',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331001',
			name: 'GamingSoft-T-BB77-IMONEY66MY',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331002',
			name: 'GamingSoft-T-BB77-MY043MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331100',
			name: 'GamingSoft-T-Besttag',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331101',
			name: 'GamingSoft-T-Besttag-JCLUB99MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331102',
			name: 'GamingSoft-T-Besttag-AWCLUB8MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331103',
			name: 'GamingSoft-T-Besttag-KCLUB8MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331104',
			name: 'GamingSoft-T-Besttag-PPCLUB99MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331105',
			name: 'GamingSoft-T-Besttag-JSTAR888MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331106',
			name: 'GamingSoft-T-Besttag-JCLUB55THB',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331200',
			name: 'GamingSoft-T-ALIBABA',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331201',
			name: 'GamingSoft-T-ALIBABA-KING66IDR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331202',
			name: 'GamingSoft-T-ALIBABA-TAXI666MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331203',
			name: 'GamingSoft-T-ALIBABA-SLOT66BND',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331204',
			name: 'GamingSoft-T-ALIBA-ALIBABA66THB',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331300',
			name: 'GamingSoft-T-CL1356',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '331301',
			name: 'GamingSoft-T-CL1356-PLAY69WINHKD',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350001',
			name: 'HWFNKWENFA-easywin459',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350003',
			name: 'HWFNKWENFA-judiwin66',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350004',
			name: 'HWFNKWENFA-8kingclub',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350005',
			name: 'HWFNKWENFA-my918',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350006',
			name: 'HWFNKWENFA-sdmbet777',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350007',
			name: 'HWFNKWENFA-we88city',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350008',
			name: 'HWFNKWENFA-Rjbet77',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350009',
			name: 'HWFNKWENFA-Rojak888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '350010',
			name: 'HWFNKWENFA-Kissy918',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '360001',
			name: 'GPlay-PSG2020',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360002',
			name: 'GPlay-Asia999',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360003',
			name: 'GPlay-Supper777',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360004',
			name: 'GPlay-TARIK',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '360005',
			name: 'GPlay-betaday888',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '360006',
			name: 'Gplay-Msnslot',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360100',
			name: 'GPlay-12P66G',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360101',
			name: 'GPlay-12P66G-Panama888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360102',
			name: 'GPlay-12P66G-vip168sa',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360103',
			name: 'GPlay-12P66G-sagame6699',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360104',
			name: 'GPlay-12P66G-ssgame666',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360105',
			name: 'GPlay-12P66G-sexygame666',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360106',
			name: 'GPlay-12P66G-sagame350',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360107',
			name: 'GPlay-12P66G-ssgame350',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360108',
			name: 'GPlay-12P66G-juad888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360109',
			name: 'GPlay-12P66G-hydra888',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360110',
			name: 'GPlay-12P66G-kingdom66',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360200',
			name: 'GPlay-GP119',
			isMasterOperator: true,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '360201',
			name: 'GPlay-GP119-GPAG1',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '370001',
			name: 'ThirtyThree-TT001',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370002',
			name: 'ThirtyThree-TT002',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370003',
			name: 'ThirtyThree-TT003',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370004',
			name: 'ThirtyThree-TT004',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370005',
			name: 'ThirtyThree-TT005',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370006',
			name: 'ThirtyThree-TT006',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370007',
			name: 'ThirtyThree-TT007',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370008',
			name: 'ThirtyThree-TT008',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370009',
			name: 'ThirtyThree-TT009',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '370010',
			name: 'ThirtyThree-TT010',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380001',
			name: 'Immortal-A96',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380002',
			name: 'Immortal-BLW',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380003',
			name: 'Immortal-LVK',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380004',
			name: 'Immortal-SSE',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380005',
			name: 'Immortal-VBG',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380006',
			name: 'Immortal-CLB',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380007',
			name: 'Immortal-JDL',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380008',
			name: 'Immortal-MAXWON',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '380009',
			name: 'Immortal-SKC',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '410001',
			name: 'Mrslotty-sgp',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '420001',
			name: 'Spadeclub-MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '420002',
			name: 'Spadeclub-SGD',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '420003',
			name: 'Spadeclub-EXCBET',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '420004',
			name: 'Spadeclub-Mgwin88',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '420005',
			name: 'Spadeclub-LKBET77',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '440001',
			name: 'Big Data Centre SDN-5dog',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '440002',
			name: 'Big Data Centre SDN-BDC-DOG',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '460001',
			name: 'Egadmin-TrillionBet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '460002',
			name: 'Egadmin-supermax996',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '480001',
			name: 'Markor-VegasKingsCasino',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480002',
			name: 'Markor-GraceMedia',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480003',
			name: 'Markor-FSBtech-UK',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480004',
			name: 'Markor-FSBtech-Curacao',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480005',
			name: 'Markor-FSBtech-Parimatch',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480006',
			name: 'Markor-BetVictor',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480007',
			name: 'Markor-FSBtech-Africa',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480008',
			name: 'Markor-BetProduction',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480009',
			name: 'Markor-BeSofted',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '480010',
			name: 'Markor-Jackpot Molly',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '490001',
			name: 'HWFNKWF-Megawin',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '490002',
			name: 'HWFNKWF-Sagabe88',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '490003',
			name: 'HWFNKWF-Sagabet98',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '500001',
			name: 'X2-slotgame666',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '500002',
			name: 'X2-x2terminal',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510001',
			name: 'Ironman-betbkk',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510002',
			name: 'Ironman-NineBet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510003',
			name: 'Ironman-Easyslot',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510004',
			name: 'Ironman-Busbet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510005',
			name: 'Ironman-Bar4bet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510006',
			name: 'Ironman-Grandsroyal',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510007',
			name: 'Ironman-Bizzbet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510008',
			name: 'Ironman-Airlinebet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510009',
			name: 'Ironman-Jokebet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510010',
			name: 'Ironman-Turnslot',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '510011',
			name: 'Ironman-SUN787',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '520001',
			name: 'Winbet-fish',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: false,
		},
		{
			fpId: '560001',
			name: 'Neftsoft-MYR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '560002',
			name: 'Neftsoft-THB',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '560003',
			name: 'Neftsoft-IDR',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '560004',
			name: 'Neftsoft-VND',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '560005',
			name: 'Neftsoft-Vegasmyr',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '570001',
			name: 'Hwin168-rapa-gresort',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '570002',
			name: 'GARYTESTSUBLINE01',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '570003',
			name: 'GARYTESTSUBLINE02',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '580001',
			name: 'HWUP8FK-GMudah',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '580002',
			name: 'HWUP8FK-GMudah2',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '580003',
			name: 'HWUP8FK-Gresort',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '580004',
			name: 'HWTGBTFNK-gbet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '640001',
			name: 'HWTGBTFNK-eastocean',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '640002',
			name: 'HWTGBTFNK-fast2win',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
		{
			fpId: '640003',
			name: 'HWTGBTFNK-tsabet',
			isMasterOperator: false,
			isReseller: false,
			isTransfer: true,
		},
	],
	returnCode: '0',
	message: '',
};
export default getOperatorListMock;
