import InfoIcon from '@mui/icons-material/Info';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function DetailButton({
	buttonType = 'contained',
	onClick,
}: {
	buttonType?: 'contained' | 'outlined' | 'text' | 'icon';
	onClick: () => void;
}) {
	const { t } = useTranslation();

	return buttonType === 'icon' ? (
		<Button variant="text" onClick={() => onClick()}>
			<InfoIcon />
		</Button>
	) : (
		<Button variant={buttonType} onClick={() => onClick()}>
			{t('Detail')}
		</Button>
	);
}
