import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoginStore } from '../store/loginStore';
import { useAccountSettingStore } from '../store/accountSettingStore';

import LanguageIcon from '@mui/icons-material/Language';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { FormControl, Stack, TextField, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ReactComponent as FunkyLogo } from '../assets/logo.svg';
import AlertMsg from '../components/common/AlertMsg';
import LanguageSelect from '../components/common/LanguageSelect';
import { LoadingButton } from '@mui/lab';

export const LoginPage: React.FC = () => {
	const go = useNavigate();
	const { t } = useTranslation();
	const { login } = useLoginStore();
	const { getSetting } = useAccountSettingStore();
	const [accountId, setAccountId] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

	const handleSignIn = async (e: SyntheticEvent) => {
		e.preventDefault();
		if (!accountId.trim() || !password.trim()) return;
		try {
			setErrorMsg('');
			setLoading(true);
			await login({ accountId, password });
			await getSetting();
			go('/');
		} catch (e) {
			e instanceof Error ? setErrorMsg(e.message) : setErrorMsg('Something wrong');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			className="flex justify-center items-center h-[100vh] relative z-0 bg-primary overflow-hidden
			before:content-[''] before:w-[100vw] before:h-[100vh] before:-z-[1] before:absolute before:top-[-40%] before:left-[-30%] before:rotate-[-15deg] before:bg-[#00539e]
			after:content-[''] after:w-[100vw] after:h-[100vh] after:-z-[1] after:absolute after:top-[35%] after:left-[50%] after:rotate-[30deg] after:bg-[#3059c0] after:rounded-[19vw]"
		>
			<div
				className="min-h-[600px] w-[400px] md:w-[90%] max-w-[900px] p-4 md:p-[3vw] rounded-[20px] bg-white md:bg-[url('../src/assets/spaceBoy.png')] bg-no-repeat flex items-center justify-center md:block"
				style={{
					backgroundPosition: '101%',
					backgroundSize: 'auto 101%',
					boxShadow: '0px 3px 44px rgba(3, 14, 117, 0.28)',
				}}
			>
				<form onSubmit={handleSignIn}>
					<Stack direction="column" className="w-[300px] space-y-6 py-2">
						<FunkyLogo />
						<FormControl fullWidth>
							<div className="p-2 text-sm font-bold flex ">
								<LanguageIcon fontSize="small" />
								<span className="ml-2">{t('language')}</span>
							</div>
							<LanguageSelect />
						</FormControl>

						<FormControl fullWidth>
							<div className="p-2 text-sm font-bold flex">
								<PersonIcon fontSize="small" />
								<span className="ml-2">{t('account')}</span>
							</div>
							<TextField required value={accountId} onChange={(e) => setAccountId(e.target.value)} />
						</FormControl>

						<FormControl className="relative" fullWidth>
							<div className="p-2 text-sm font-bold flex">
								<LockIcon fontSize="small" />
								<span className="ml-2">{t('password')}</span>
							</div>
							<TextField
								autoComplete="off"
								type={showPassword ? 'text' : 'password'}
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<IconButton
								className="absolute right-3 bottom-0"
								aria-label="toggle password visibility"
								onClick={() => setShowPassword((value) => !value)}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</FormControl>
						<div className="pt-16 relative">
							<div className="absolute top-[10px]">{errorMsg && <AlertMsg content={errorMsg} />}</div>
							<LoadingButton type="submit" variant="gradient" className="py-3" fullWidth loading={loading}>
								{t('login')}
							</LoadingButton>
						</div>
					</Stack>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
