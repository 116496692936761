import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { Option } from '../components/common/SearchSelect';
import PopupEnum from '../enum/popupEnum';
import api from '../api';

export interface ICurrencyListStore {
	currencyList: string[];
	initCurrencyList: (openPopup: (popup: PopupEnum) => void) => void;
	transferToOptions: () => Option[] | [];
	clear: () => void;
}

const key = 'currencyListStore';

export const useCurrencyListStore = create<ICurrencyListStore>()(
	devtools(
		persist(
			(set, get) => ({
				currencyList: [],
				initCurrencyList: async (openPopup) => {
					if (get().currencyList.length > 0) return;
					try {
						const { currencyList } = await api.getCurrencyList();
						set((state) => ({ ...state, currencyList }));
					} catch (error) {
						openPopup(PopupEnum.InternalServerError);
					}
				},
				transferToOptions: () => {
					const result = get().currencyList.map((item) => ({ id: item, label: item }));
					return result;
				},
				clear: () => set({ currencyList: [] }),
			}),
			{
				name: key,
				getStorage: () => sessionStorage,
			}
		)
	)
);
