import { Typography } from '@mui/material';
import StreamIcon from '@mui/icons-material/Stream';

type Props = {
	content: string;
};

const PageTitle = ({ content }: Props) => (
	<Typography variant="pageTitle" className="flex items-center gap-2">
		<StreamIcon />
		{content}
	</Typography>
);

export default PageTitle;
