export const searchWinLoseReportByPlayerMock = {
	winLoseReportByPlayerInfo: {
		operatorName: 'Sylph',
		timeZone: '0',
		playerCurrencyList: [
			{
				playerId: 'Fun880645MYR',
				currency: 'MYR',
				betCount: '1',
				validTurnover: '5.00',
				playerWinLose: '4.75',
				operatorWinLose: '-4.75',
				isTestAccount: true,
			},
			{
				playerId: 'zqVNkSrrYAvpOc-2fJCUph-2bw-3d-3d',
				currency: 'USD',
				betCount: '2',
				validTurnover: '10.00',
				playerWinLose: '3.55',
				operatorWinLose: '-3.55',
				isTestAccount: false,
			},
		],
	},
	currentPage: 1,
	pageCount: 1,
	pageSize: 100,
	rowCount: 2,
	returnCode: '0',
	message: '',
};

export default searchWinLoseReportByPlayerMock;
