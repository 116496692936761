import { Search } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
	handleSearch: () => void;
};

export const SearchButton = ({ handleSearch }: Props) => {
	const { t } = useTranslation();
	return (
		<Button variant="gradient" endIcon={<Search />} onClick={handleSearch} className="mx-auto">
			{t('search.Search')}
		</Button>
	);
};

export default SearchButton;
