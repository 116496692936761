import { SearchTransferWalletBetListResponse } from '../../models';

export const searchTransferWalletBetListMock: SearchTransferWalletBetListResponse = {
	returnCode: '0',
	message: '',
	currentPage: 1,
	pageCount: 1,
	pageSize: 100,
	rowCount: 2,
	fpId: '0',
	timeZone: '4',
	agentName: 'test',
	data: [
		{
			statementDate: '1',
			betTime: '1',
			refNo: '112333333333333333333333333333333333333333333333333333333333333333333333333333333333',
			betStatus: '1',
			gameCode: '1',
			currency: '1',
			validTurnover: '1',
			betAmount: '1',
			winLoss: '1',
		},
		{
			statementDate: '2',
			betTime: '2',
			refNo: '112333333333333333333333333333333333333333333333333333333333333333333333333333333333',
			betStatus: '2',
			gameCode: '2',
			currency: '2',
			validTurnover: '2',
			betAmount: '2',
			winLoss: '2',
		},
		{
			statementDate: '3',
			betTime: '3',
			refNo: '112333333333333333333333333333333333333333333333333333333333333333333333333333333333',
			betStatus: '3',
			gameCode: '3',
			currency: '3',
			validTurnover: '3',
			betAmount: '3',
			winLoss: '3',
		},
	],
};

export default searchTransferWalletBetListMock;
