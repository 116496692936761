import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Paper, Button, TextField, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import SectionTitle from '../components/common/SectionTitle';
import PageTitle from '../components/common/PageTitle';
import { EnumSelectSection } from '../components/common/EnumSelectSection';
import { Option } from '../components/common/SearchSelect';
import { OperatorSection } from '../components/OperatorSection';
import AccountTypeEnum from '../enum/accountTypeEnum';
import RoleEnum from '../enum/roleEnum';
import PopupEnum from '../enum/popupEnum';
import { I18nStoreType } from '../i18n/react-i18next';
import { useTranslation } from 'react-i18next';
import { useError } from '../hook/useError';
import { usePopupStore } from '../store/popupStore';
import { useAccountSettingStore } from '../store/accountSettingStore';
import api from '../api';
import { PASSWORD_RULE } from '../utils/regexp';
import AccountTypeSection from '../components/AccountTypeSection';
import accountTypeList from '../enum/accountTypeList';

const validationSchema = yup
	.object({
		accountId: yup.string().required('inputErrorMsg.Required'),
		accountName: yup.string().required('inputErrorMsg.Required'),
		password: yup
			.string()
			.min(8, 'inputErrorMsg.Size_8_10')
			.max(10, 'inputErrorMsg.Size_8_10')
			.matches(PASSWORD_RULE, 'inputErrorMsg.Valid')
			.required('inputErrorMsg.Required'),
		email: yup.string(),
		contactPhone: yup.string(),
		mobilePhone: yup.string(),
		remark: yup.string(),
	})
	.required();

type FormData = {
	accountId: string;
	accountName: string;
	password: string;
	email?: string;
	contactPhone?: string;
	mobilePhone?: string;
	remark?: string;
};

const getAccountList = (role: RoleEnum) => {
	if (role === RoleEnum.Admin) return accountTypeList;
	if (role === RoleEnum.Reseller) return accountTypeList.filter((option) => option.id !== AccountTypeEnum.All);
	return [];
};

const defaultAccountType = (accountType: AccountTypeEnum) =>
	accountType === AccountTypeEnum.All ? null : accountTypeList.find((option) => option.id === accountType) || null;

const getRoleEnum = (role: RoleEnum) => {
	if (role === RoleEnum.Admin) return RoleEnum;
	if (role === RoleEnum.Reseller) return { [RoleEnum.Master]: RoleEnum.Master, [RoleEnum.Manager]: RoleEnum.Manager };
	return { [RoleEnum.Manager]: RoleEnum.Manager };
};

const defaultRole = (role: RoleEnum) =>
	role === RoleEnum.Master ? { id: RoleEnum.Manager, label: RoleEnum.Manager } : null;

type Props = {};

export const MemberCreatePage = (props: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { accountType, role } = useAccountSettingStore();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [accountTypeOption, setAccountType] = useState<Option | null>(null);
	const [roleOption, setRole] = useState<Option<RoleEnum> | null>(null);
	const [op, setOp] = useState<Option | null>(null);
	const [accountTypeError, setAccountTypeError] = useState<string>('');
	const [roleError, setRoleError] = useState<string>('');
	const [opError, setOpError] = useState<string>('');
	const { openPopup } = usePopupStore();
	const { handleError } = useError();
	const {
		register,
		handleSubmit,
		formState: { isSubmitted, errors },
	} = useForm<FormData>({
		resolver: yupResolver(validationSchema),
	});

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

	const goBack = () => navigate(-1);

	const onSubmitHandler = async (data: FormData) => {
		try {
			if (!accountTypeOption || !roleOption || !op) {
				!accountTypeOption && setAccountTypeError('inputErrorMsg.Required_Selected');
				!roleOption && setRoleError('inputErrorMsg.Required_Selected');
				!op && setOpError('inputErrorMsg.Required_Selected');
				return;
			}

			if (
				accountTypeOption.id === AccountTypeEnum.All &&
				(roleOption.id === RoleEnum.Master || roleOption.id === RoleEnum.Manager)
			) {
				throw PopupEnum.SystemRoleTypeNotMatch;
			}

			openPopup(PopupEnum.Loading);
			await api.createAccount({
				...data,
				role: roleOption.id,
				accountType: accountTypeOption.id,
				operatorId: Number(op.id),
			});
			openPopup(PopupEnum.CreateSuccess);
			goBack();
		} catch (error: any | PopupEnum) {
			handleError(error);
		}
	};

	useEffect(() => {
		if (!accountType || !role) return;
		setAccountType(defaultAccountType(accountType));
		setRole(defaultRole(role));
	}, [accountType, role]);

	useEffect(() => {
		if (isSubmitted) {
			accountTypeOption ? setAccountTypeError('') : setAccountTypeError('inputErrorMsg.Required_Selected');
			roleOption ? setRoleError('') : setRoleError('inputErrorMsg.Required_Selected');
			op ? setOpError('') : setOpError('inputErrorMsg.Required_Selected');
		}
	}, [isSubmitted, accountTypeError, accountTypeOption, roleError, roleOption, opError, op]);

	return (
		<div className="space-y-4 lg:w-[700px] w-auto mx-auto">
			<PageTitle content={t('management.MemberCreate.Title')} />
			<Paper
				className="grid grid-cols-12 gap-3 mt-4 p-8 bg-gray-50"
				component="form"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				<div className="space-y-3 col-span-12 md:col-span-6 lg:col-span-4">
					<SectionTitle content={t('management.MemberForm.AccountId')} required />
					<TextField
						className="w-full"
						variant="outlined"
						autoComplete="off"
						error={!!errors['accountId']}
						helperText={errors['accountId'] ? (t(errors['accountId'].message as keyof I18nStoreType) as string) : ''}
						{...register('accountId')}
					/>
				</div>
				<div className="space-y-3 col-span-12 md:col-span-6 lg:col-span-4">
					<SectionTitle content={t('management.MemberForm.AccountName')} required />
					<TextField
						className="w-full"
						variant="outlined"
						autoComplete="off"
						error={!!errors['accountName']}
						helperText={
							errors['accountName'] ? (t(errors['accountName'].message as keyof I18nStoreType) as string) : ''
						}
						{...register('accountName')}
					/>
				</div>
				<div className="space-y-3 relative col-span-12 md:col-span-6 lg:col-span-4">
					<SectionTitle content={t('management.MemberForm.Password')} required />
					<TextField
						className="w-full"
						variant="outlined"
						type={showPassword ? 'text' : 'password'}
						autoComplete="off"
						error={!!errors['password']}
						helperText={errors['password'] ? (t(errors['password'].message as keyof I18nStoreType) as string) : ''}
						{...register('password')}
					/>
					<IconButton
						className="absolute right-3"
						aria-label="toggle password visibility"
						onClick={() => setShowPassword((value) => !value)}
						onMouseDown={handleMouseDownPassword}
						edge="end"
					>
						{showPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</div>
				{role && accountType && (
					<AccountTypeSection
						widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
						option={accountTypeOption}
						setOption={setAccountType}
						options={getAccountList(role)}
						errorText={accountTypeError ? (t(accountTypeError as keyof I18nStoreType) as string) : ''}
						required
						disabled={accountType !== AccountTypeEnum.All}
					/>
				)}
				{role && (
					<EnumSelectSection
						title={t('management.MemberForm.RoleType')}
						label={t('management.MemberForm.Select')}
						widthStyle="w-full col-span-12 md:col-span-6 lg:col-span-3"
						option={roleOption}
						setOption={setRole}
						optionEnum={getRoleEnum(role)}
						errorText={roleError ? (t(roleError as keyof I18nStoreType) as string) : ''}
						required
						disabled={role === RoleEnum.Master}
					/>
				)}
				<OperatorSection
					widthStyle="w-full col-span-12 md:col-span-6"
					op={op}
					setOp={setOp}
					filterRole={roleOption?.id}
					errorText={opError ? (t(opError as keyof I18nStoreType) as string) : ''}
					required
				/>

				<div className="space-y-3 w-full col-span-12 md:col-span-6">
					<SectionTitle content={t('management.MemberForm.ContactPhone')} />
					<TextField className="w-full" variant="outlined" autoComplete="off" {...register('contactPhone')} />
				</div>
				<div className="space-y-3 w-full col-span-12 md:col-span-6">
					<SectionTitle content={t('management.MemberForm.MobilePhone')} />
					<TextField className="w-full" variant="outlined" autoComplete="off" {...register('mobilePhone')} />
				</div>
				<div className="space-y-3 w-full col-span-12">
					<SectionTitle content={t('management.MemberForm.Email')} />
					<TextField className="w-full" variant="outlined" autoComplete="off" {...register('email')} />
				</div>

				<div className="space-y-3 w-full col-span-12">
					<SectionTitle content={t('management.MemberForm.Remark')} />
					<TextField className="w-full" variant="outlined" autoComplete="off" {...register('remark')} />
				</div>

				<div className="pt-8 col-span-12 flex justify-center flex-wrap gap-4">
					<Button variant="redGradient" className="color-[#ae0000]" onClick={goBack}>
						{t('management.MemberCreate.BackButton')}
					</Button>
					<LoadingButton variant="gradient" type="submit" className="">
						{t('management.MemberCreate.SubmitButton')}
					</LoadingButton>
				</div>
			</Paper>
		</div>
	);
};

export default MemberCreatePage;
