import AddIcon from '@mui/icons-material/Add';
import { Button, Pagination, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { EnumSelectSection } from '../components/common/EnumSelectSection';
import { InputSection } from '../components/common/InputSection';
import PageTitle from '../components/common/PageTitle';
import { Option } from '../components/common/SearchSelect';
import LinkCol from '../components/LinkCol';
import { accountIdCol, restMemberColumns } from '../components/ManagementTableColumns';
import { OperatorSection } from '../components/OperatorSection';
import { SearchWrapper } from '../components/SearchWrapper';
import { StatusSection } from '../components/StatusSection';
import { Table } from '../components/Table';
import PopupEnum from '../enum/popupEnum';
import RoleEnum from '../enum/roleEnum';
import StatusEnum from '../enum/statusEnum';
import { useCustomPage } from '../hook/useCustomPage';
import { useError } from '../hook/useError';
import { I18nStoreType } from '../i18n/react-i18next';
import { Account } from '../models';
import { useAccountSettingStore } from '../store/accountSettingStore';
import { usePopupStore } from '../store/popupStore';

type Props = {};

const getRoleEnum = (role: RoleEnum) => {
	if (role === RoleEnum.Admin) return RoleEnum;
	if (role === RoleEnum.Reseller) return { [RoleEnum.Master]: RoleEnum.Master, [RoleEnum.Manager]: RoleEnum.Manager };
	return { [RoleEnum.Manager]: RoleEnum.Manager };
};

export const MemberPage = (props: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { role } = useAccountSettingStore();
	const [accountList, setAccountList] = useState<Account[]>([]);
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();
	const [userName, setUserName] = useState<string>('');
	const [op, setOp] = useState<Option | null>(null);
	const [roleOption, setRole] = useState<Option<RoleEnum> | null>(null);
	const [status, setStatus] = useState<Option | null>(null);
	const [opError, setOpError] = useState<string>('');
	const [accountPageList, setAccountPageList] = useState<Account[]>([]);
	const { currentPage, initPaged, getPaged, pageCount } = useCustomPage(10, setAccountPageList);

	const handleSearch = async () => {
		try {
			if (!op) {
				setOpError('inputErrorMsg.Required_Selected');
				throw PopupEnum.InvalidInput;
			}
			openPopup(PopupEnum.Loading);

			const res = await api.queryAccount({
				operatorId: Number(op?.id),
				accountId: userName.trim(),
				role: roleOption?.id || null,
				status: (status?.id as StatusEnum) || null,
				containChild: true,
			});

			setAccountList(res.accountList);
			initPaged(res.accountList);
			closePopup();
		} catch (error: any | PopupEnum) {
			handleError(error);
		}
	};

	useEffect(() => {
		if (opError && op) setOpError('');
	}, [opError, op]);

	return (
		<div className="space-y-4 mx-auto">
			<Stack spacing={2} direction="row" justifyContent="space-between">
				<PageTitle content={t('management.MemberQuery.Title')} />
				<Button
					variant="gradient"
					className="bg-[#0096fd] rounded-2xl"
					endIcon={<AddIcon />}
					onClick={() => navigate('/member/create')}
				>
					{t('management.MemberCreate.SubmitButton')}
				</Button>
			</Stack>
			<SearchWrapper handleSearch={handleSearch}>
				<InputSection title={t('management.MemberQuery.UserName')} value={userName} setValue={setUserName} />
				<OperatorSection
					op={op}
					setOp={setOp}
					errorText={opError ? (t(opError as keyof I18nStoreType) as string) : ''}
					required
				/>
				{role && (
					<EnumSelectSection
						title={t('management.MemberForm.RoleType')}
						label={t('management.MemberForm.Select')}
						widthStyle="w-[160px]"
						option={roleOption}
						setOption={setRole}
						optionEnum={getRoleEnum(role)}
					/>
				)}
				<StatusSection status={status} setStatus={setStatus} />
			</SearchWrapper>
			{!!accountList.length && (
				<div className="space-y-12">
					<Table
						list={accountPageList}
						columns={[
							(t) => ({
								...accountIdCol(t),
								renderCell: (params) => (
									<LinkCol value={params.value} click={() => navigate('/member/update', { state: params.row })} />
								),
							}),
							...restMemberColumns,
						]}
					/>

					<Pagination
						count={pageCount}
						color="primary"
						page={currentPage}
						onChange={(e, value) => getPaged(accountList, value)}
					/>
				</div>
			)}
		</div>
	);
};

export default MemberPage;
