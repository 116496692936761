import { Pagination } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { InputSection } from '../components/common/InputSection';
import PageTitle from '../components/common/PageTitle';
import { Option, optionAll } from '../components/common/SearchSelect';
import { CurrencySection } from '../components/CurrencySection';
import { DateSelectSection } from '../components/DateSelectSection';
import DownloadExcelButton from '../components/DownloadExcelButton';
import { OperatorSection } from '../components/OperatorSection';
import { txTransactionHistoryColumns } from '../components/ReportTableColumns';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import { TableHeader } from '../components/TableHeader';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { useFile } from '../hook/useFile';
import { usePage } from '../hook/usePage';
import { useRequestId } from '../hook/useRequestId';
import {
	DownloadTransferWalletTransactionHistoryRequest,
	SearchTransferWalletTransactionHistoryRequest,
	SearchTransferWalletTransactionHistoryResponse,
} from '../models';
import { usePopupStore } from '../store/popupStore';
import { checkDateRange, formatDate } from '../utils/date';

export const TransferWalletTransactionHistoryPage = () => {
	const { t } = useTranslation();
	const [data, setData] = useState<SearchTransferWalletTransactionHistoryResponse | null>(null);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [playerId, setPlayerId] = useState<string>('');
	const [op, setOp] = useState<Option | null>(null);
	const [currency, setCurrency] = useState<Option | null>(optionAll);
	const { requestId, updateSearch } = useRequestId(handleSearch);
	const [loading, setLoading] = useState(false);
	const { handleFile } = useFile();
	const fileRequest = useRef<DownloadTransferWalletTransactionHistoryRequest>();
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();
	const { currentPage, handlePage, pageSize, pageCount } = usePage();

	async function handleSearch(page: number, searchId: string = requestId) {
		try {
			if (!startDate || !endDate || !playerId || !currency || !op) throw PopupEnum.InvalidInput;
			if (checkDateRange(startDate, endDate, 31)) throw PopupEnum.OverSearchDay;
			openPopup(PopupEnum.Loading);
			const req: SearchTransferWalletTransactionHistoryRequest = {
				startDate: formatDate(startDate),
				endDate: formatDate(endDate),
				currency: currency?.label,
				playerId,
				fpId: op.id,
				currentPage: page,
				pageSize,
				searchId,
			};
			const res = await api.searchTransferWalletTransactionHistory(req);
			if (!res.rowCount) throw PopupEnum.ResultNotFound;
			handlePage(res.currentPage, res.pageCount);
			fileRequest.current = req;
			setData(res);
			closePopup();
		} catch (error: any | PopupEnum) {
			setData(null);
			handleError(error);
		}
	}

	const downloadTxTransactionHistory = async () => {
		try {
			setLoading(true);
			if (!fileRequest.current) throw PopupEnum.InvalidInput;
			const [excelFile, disposition] = await api.downloadTransferWalletTransactionHistory(fileRequest.current);
			handleFile(excelFile, disposition);
		} catch (error) {
			handleError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="space-y-4">
			<PageTitle content={t('sidebar.report.transferWalletTransactionHistory')} />
			<SearchWrapper handleSearch={() => updateSearch()} showCurrencyHint>
				<DateSelectSection
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				<InputSection
					title={t('PlayerId')}
					label={t('search.Input_PlayerId')}
					value={playerId}
					setValue={setPlayerId}
				/>
				<CurrencySection currency={currency} setCurrency={setCurrency} hasAll />
				<OperatorSection op={op} setOp={setOp} />
			</SearchWrapper>
			{!!data && (
				<>
					<div className="space-y-12">
						<div className="flex justify-end p-2">
							<DownloadExcelButton download={downloadTxTransactionHistory} isAll loading={loading} />
						</div>
						<Table
							list={data.data}
							columns={txTransactionHistoryColumns}
							components={{
								Toolbar: () => <TableHeader info={data} />,
							}}
						/>
					</div>
					{!!currentPage && (
						<Pagination
							count={pageCount}
							color="primary"
							page={currentPage}
							onChange={(e, value) => value !== currentPage && handleSearch(value)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default TransferWalletTransactionHistoryPage;
