import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../api';
import { Option } from '../components/common/SearchSelect';
import PopupEnum from '../enum/popupEnum';
import { GameProviderData } from '../models';

export interface IGameProviderListStore {
	gpList: GameProviderData[];
	initGpList: (openPopup: (popup: PopupEnum) => void) => void;
	transferToOptions: () => Option[] | [];
	clear: () => void;
}

const key = 'useGameProviderListStore';

export const useGameProviderListStore = create<IGameProviderListStore>()(
	devtools(
		persist(
			(set, get) => ({
				gpList: [],
				initGpList: async (openPopup) => {
					if (get().gpList.length > 0) return;
					try {
						const { list } = await api.getGameProviderList();
						set((state) => ({ ...state, gpList: list }));
					} catch (error) {
						console.log(error);
						openPopup(PopupEnum.InternalServerError);
					}
				},
				transferToOptions: () => {
					const result = get().gpList.map((item) => ({ id: item.userAgent, label: item.gameProviderName }));
					return result;
				},
				clear: () => set({ gpList: [] }),
			}),
			{
				name: key,
				getStorage: () => sessionStorage,
			}
		)
	)
);
