import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n/configs';
import './index.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<CssBaseline />
						<App />
					</LocalizationProvider>
				</ThemeProvider>
			</BrowserRouter>
		</StyledEngineProvider>
	</React.StrictMode>
);
