import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

const LanguageSelect: React.FC = () => {
	const { i18n } = useTranslation();

	const handleChange = (event: SelectChangeEvent) => {
		i18n.changeLanguage(event.target.value);
	};

	return (
		<Select value={i18n.language} onChange={handleChange}>
			<MenuItem value={'EN'}>English</MenuItem>
			<MenuItem value={'ZH_CN'}>简体中文</MenuItem>
		</Select>
	);
};

export default LanguageSelect;
