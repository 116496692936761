import { Pagination } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { DateSelectSection } from '../components/DateSelectSection';
import DownloadExcelButton from '../components/DownloadExcelButton';
import { GameSection } from '../components/GameSection';
import LinkCol from '../components/LinkCol';
import { OperatorSection } from '../components/OperatorSection';
import { refNoCol, restTransactionReportColumns, sumTransactionReportColumns } from '../components/ReportTableColumns';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import { TableHeader } from '../components/TableHeader';
import { InputSection } from '../components/common/InputSection';
import PageTitle from '../components/common/PageTitle';
import { Option } from '../components/common/SearchSelect';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { useFile } from '../hook/useFile';
import { usePage } from '../hook/usePage';
import { useRequestId } from '../hook/useRequestId';
import {
	DownloadTransactionReportRequest,
	GetBetDetailByRefNoRequest,
	SearchTransactionReportRequest,
	SearchTransactionReportResponse,
	SearchWinLoseReportByPlayerResponse,
} from '../models';
import { usePopupStore } from '../store/popupStore';
import { checkDateRange, formatDate } from '../utils/date';

export const TransactionReportPage = () => {
	const { t, i18n } = useTranslation();
	const [sumData, setSumData] = useState<SearchWinLoseReportByPlayerResponse | null>(null);
	const [data, setData] = useState<SearchTransactionReportResponse | null>(null);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [playerId, setPlayerId] = useState<string>('');
	const [op, setOp] = useState<Option | null>(null);
	const [game, setGame] = useState<Option | null>(null);
	const [loading, setLoading] = useState(false);
	const { requestId, updateSearch } = useRequestId(handleSearch);
	const { handleFile } = useFile();
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();
	const { currentPage, handlePage, pageSize, pageCount } = usePage();
	const fileRequest = useRef<DownloadTransactionReportRequest>();

	async function searchTransactionReport(req: SearchTransactionReportRequest) {
		const res = await api.searchTransactionReport(req);
		if (!res.rowCount) throw PopupEnum.ResultNotFound;
		handlePage(res.currentPage, res.pageCount);
		fileRequest.current = req;
		setData(res);
	}

	async function searchWinLoseReportByPlayer(req: SearchTransactionReportRequest) {
		const res = await api.searchWinLoseReportByPlayer({
			...req,
			includeTestAccount: true,
		});
		setSumData(res);
	}

	async function handleSearch(page: number, searchId: string = requestId) {
		try {
			if (!startDate || !endDate || !playerId || !game || !op) throw PopupEnum.InvalidInput;
			if (checkDateRange(startDate, endDate, 31)) throw PopupEnum.OverSearchDay;
			openPopup(PopupEnum.Loading);
			const req: SearchTransactionReportRequest = {
				startDate: formatDate(startDate),
				endDate: formatDate(endDate),
				playerId,
				gameCode: game.id,
				fpId: op.id,
				currentPage: page,
				pageSize,
				searchId,
			};

			await searchTransactionReport(req);
			if (req.currentPage === 1) {
				await searchWinLoseReportByPlayer(req);
			}
			closePopup();
		} catch (error: any | PopupEnum) {
			setData(null);
			handleError(error);
		}
	}

	const downloadTransactionReport = async () => {
		try {
			setLoading(true);
			if (!fileRequest.current) throw PopupEnum.InvalidInput;
			const [excelFile, disposition] = await api.downloadTransactionReport(fileRequest.current);
			handleFile(excelFile, disposition);
		} catch (error) {
			handleError(error);
		} finally {
			setLoading(false);
		}
	};

	const getBetDetailByRefNo = async (refNo: string) => {
		try {
			if (!data) return;
			const row = data.data.find((row) => row.refNo === refNo);
			if (!row) return;

			const req: GetBetDetailByRefNoRequest = {
				refNo,
				gameCode: row.gameCode,
				fpId: data.fpId,
				playerId: data.playerId,
				lang: i18n.language,
			};

			const { url } = await api.getBetDetailByRefNo(req);
			if (!url) throw PopupEnum.ResultNotFound;

			window.open(url, '_blank');
		} catch (error: any) {
			console.log(error);
			handleError(error);
		}
	};

	return (
		<div className="space-y-4">
			<PageTitle content={t('sidebar.report.transaction')} />
			<SearchWrapper handleSearch={() => updateSearch()} showCurrencyHint>
				<DateSelectSection
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				<InputSection
					title={t('PlayerId')}
					label={t('search.Input_PlayerId')}
					value={playerId}
					setValue={setPlayerId}
				/>
				<GameSection game={game} setGame={setGame} hasAll />
				<OperatorSection op={op} setOp={setOp} />
			</SearchWrapper>
			{!!data?.data?.length && (
				<>
					<div className="space-y-12">
						<div className="flex justify-end p-2">
							<DownloadExcelButton download={downloadTransactionReport} isAll loading={loading} />
						</div>
						<Table
							list={sumData?.winLoseReportByPlayerInfo.playerCurrencyList || []}
							columns={sumTransactionReportColumns}
							components={{ Toolbar: () => <TableHeader info={data} /> }}
						/>
						<Table
							list={data.data}
							columns={[
								(t) => ({
									...refNoCol(t),
									renderCell: (params) => <LinkCol value={params.value} click={getBetDetailByRefNo} />,
								}),
								...restTransactionReportColumns,
							]}
						/>
					</div>
					{!!currentPage && (
						<Pagination
							count={pageCount}
							color="primary"
							page={currentPage}
							onChange={(e, value) => value !== currentPage && handleSearch(value)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default TransactionReportPage;
