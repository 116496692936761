import axios, { AxiosError } from 'axios';
import PopupEnum from '../enum/popupEnum';
import ReturnCodeEnum from '../enum/returnCodeEnum';
import { ResponseBase } from '../models';
import { useLoginStore } from '../store/loginStore';
import { usePopupStore } from '../store/popupStore';
import { verifyKeyExisted } from '../utils/verify';

type Props = { handleError: (file: any) => void };

export const useError = (): Props => {
	const { logout } = useLoginStore();
	const { openPopup, closePopup } = usePopupStore();

	const handleAPIError = (error: AxiosError) => {
		if (error.response?.status === 401) {
			logout();
			return;
		}
		const errorResponse = error.response?.data as ResponseBase;
		if (errorResponse.returnCode) {
			throw ReturnCodeEnum[errorResponse.returnCode];
		}
		throw PopupEnum.InternalServerError;
	};

	const handleError = (error: any) => {
		try {
			closePopup();

			if (axios.isAxiosError(error)) {
				handleAPIError(error);
				return;
			}
			throw error;
		} catch (error) {
			if (typeof error === 'string' && verifyKeyExisted(PopupEnum, error)) {
				openPopup(error as PopupEnum);
			} else openPopup(PopupEnum.InternalServerError);
		}
	};

	return { handleError };
};
